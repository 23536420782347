import { createAction } from 'typesafe-actions';

export const setUiMobileFilterOverlay = createAction(
  'ui/SET_MOBILE_FILTER_OVERLAY',
  (resolve) => (val:boolean) => resolve(val)
);
export const setUiTripChangingTariffsloader = createAction(
  'ui/SET_TRIP_CHANGING_TARIFFS_LOADER',
  (resolve) => (val:boolean) => resolve(val)
);
export const setUiMobileNoScrolling = createAction(
  'ui/SET_MOBILE_NO_SCROLLING',
  (resolve) => (val:boolean) => resolve(val)
);
export const setUiIsSelectingTrip = createAction(
  'ui/SET_IS_SELECTING_TRIP',
  (resolve) => (val:boolean) => resolve(val)
);
export const setUiIsSelectingHotel = createAction(
  'ui/SET_IS_SELECTING_HOTEL',
  (resolve) => (val:boolean) => resolve(val)
);
