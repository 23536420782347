import * as React from 'react';
import { useSelector } from 'react-redux';
import { isAfter, isBefore, isSameDay } from 'date-fns';

// Utils
import { notify } from '@toolkit/util/app';
import { isNil } from '@src/shared/src/util/general';
import dayPickerLocale from '@src/toolkit/util/dayPickerLocale';
// Constants
import { DIRECTION, SEARCH_TYPE } from '@src/shared/src/const/app';
import { NOTIF_TYPE } from '@src/shared/src/const/app';
// Actions
// Models
// Interfaces
import { IRootState } from '@src/store';
// Components
import DayPicker from 'react-day-picker';
import { SearchBarTimeSelector } from './SearchBarTimeSelector';
import { SearchBarTimeWindowSelector } from './SearchBarTimeWindowSelector';
import { MenuListDevider } from '@toolkit/ui';
// Styles
import '../styles/SearchBarDateTimeSelector.scss';

type Props = {
  show: boolean;
  value: Date;
  minDate: Date;
  maxDate: Date;
  direction: DIRECTION;
  onDateChange?: (nDate: Date) => void;
  onMinDateNullErrorMsg?: string;
};

const SearchBarDateTimeSelector: React.FC<Props> = ({
  show,
  value,
  minDate,
  maxDate,
  direction,
  onDateChange,
  onMinDateNullErrorMsg,
}) => {
  const search = useSelector((state: IRootState) => state.search.currentSearch);

  const handleDateChange = (date: Date) => {
    if (isNil(minDate) && !isNil(onMinDateNullErrorMsg)) {
      notify(onMinDateNullErrorMsg, NOTIF_TYPE.ERROR);
    } else if (
      isSameDay(date, minDate) ||
      isSameDay(date, maxDate) ||
      (isAfter(date, minDate) && isBefore(date, maxDate))
    ) {
      onDateChange(date);
    }
  };

  const modifiers = {
    active: (day: Date) => isAfter(day, minDate) && isBefore(day, value),
    startDate: (day: Date) => isSameDay(day, minDate),
  };

  return (
    <div className="tcp-search-bar-date-time-selector">
      {show && <DayPicker // force DayPicker to rerender
        localeUtils={dayPickerLocale}
        onDayClick={handleDateChange}
        fromMonth={minDate}
        toMonth={maxDate}
        modifiers={modifiers}
        initialMonth={value ? value : minDate}
        selectedDays={[value]}
        disabledDays={{ before: minDate, after: maxDate }}
      />}

      {(search.searchType === SEARCH_TYPE.ALL || search.searchType === SEARCH_TYPE.OUTBOUND) && (
        <>
          <MenuListDevider />
          <SearchBarTimeWindowSelector
            searchDepAt={search.depAt}
            searchArrAt={search.arrAt}
            direction={direction}
          />
        </>
      )}

      {search.searchType === SEARCH_TYPE.RENTAL && <SearchBarTimeSelector direction={direction} />}
    </div>
  );
};

export default SearchBarDateTimeSelector;
