import * as React from 'react';

// Utils
import { navigate, baseErrorCond } from '@toolkit/util/app';
import { cond, isEmpty, isNil } from '@src/shared/src/util/general';
import { useDispatch } from 'react-redux';
import useBreakpoints from '@toolkit/util/useBreakpoints';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import {
  isOnewaySearch,
  isMultiCitySearch,
  isHotelOnlySearch,
  isRentalOnlySearch,
  isRoundtripSearch,
  shouldSearchForHotels,
} from '@src/shared/src/util/search';
// Constants
import { DIRECTION } from '@src/shared/src/const/app';
import { API_URL } from '@src/shared/src/const/api';
import { ROUTES } from '@toolkit/const/app';
// Actions, Models & Interfaces
import { selectors } from '@src/shared/src';
import { adminUserSelectors, searchSelectors } from '@src/shared/src/selectors';
import { searchActions } from '@src/shared/src/actions';
import { IRootState } from '@src/store';
// Components
import {
  SearchBarDateTime,
  SearchBarFilter,
  SearchBarPassengers,
  SearchBarConcierge,
  SearchBarRoute,
  SearchSubmitButton,
  SearchOptions,
} from '@pod/search/components';
import LogoPrimary from '@toolkit/ui/LogoPrimary';
import { BENotificationBar, RebookingBanner } from '@toolkit/ui';
import ToggleOnlyOutboundButton from '../components/ToggleOnlyOutboundButton';
import HotelWarningModal from '../components/HotelWarningModal';
import ToggleRouteButton from '../components/ToggleRouteButton';
// Styles
import '../styles/SearchBar.scss';

const SearchBarConn: React.FC = () => {
  const [isSearchButtonLoading, SetIsSearchButtonLoading] = React.useState(false);
  const dispatch = useDispatch();
  const { isLg } = useBreakpoints();
  const [showHotelWarning, setShowHotelWarning] = React.useState(false);

  const search = useSelector((state: IRootState) => selectors.search.search(state.search));
  const profile = useSelector((state: IRootState) => state.adminUser.profile);
  const isCurrentUserAdmin = useSelector((state: IRootState) =>
    adminUserSelectors.isCurrentUserAdmin(state.adminUser),
  );
  const isCurrentUserTravelAssistant = useSelector((state: IRootState) =>
    adminUserSelectors.isCurrentUserTravelAssistant(state.adminUser),
  );
  const isCurrentUserManager = useSelector((state: IRootState) =>
    adminUserSelectors.isCurrentUserManager(state.adminUser),
  );
  const searchPassengers = useSelector((state: IRootState) =>
    searchSelectors.searchPassengers(state.search),
  );
  const organization = useSelector((state: IRootState) => state.organization.org);
  const isRebookingSearch = useSelector((state: IRootState) =>
    searchSelectors.isRebookingSearch(state),
  );

  React.useEffect(() => {
    if (!isNil(profile) && !isCurrentUserTravelAssistant && isEmpty(searchPassengers)) {
      dispatch(searchActions.setSearchPassengers([profile.asPassenger]));
    }
  }, [profile?.id]);

  const handleStartSearch = () => {
    setShowHotelWarning(false);
    SetIsSearchButtonLoading(true);
    dispatch(
      searchActions.startSearchAsync.request({
        onSuccess: (searchRes) => {
          if (isRentalOnlySearch(search)) {
            window.location.href = `${API_URL.DEFAULT}/api/rental/searches/${searchRes.id}`;
          } else if (isHotelOnlySearch(search)) {
            navigate(`${ROUTES.HOTELS}${searchRes.id}`);
          } else {
            navigate(`${ROUTES.TRIPS.OUTWARD}${searchRes.id}`);
          }
        },
        onError: (err) => {
          SetIsSearchButtonLoading(false);
          return cond(baseErrorCond)(err);
        },
        search,
      }),
    );
  };

  const onStartSearch = () => {
    if (isMultiCitySearch(search) && !shouldSearchForHotels(search)) setShowHotelWarning(true);
    else handleStartSearch();
  };

  return (
    <>
      <BENotificationBar currentPage="Home" />

      <aside className={classNames('search-bar', { 'has--brand-logo': organization?.logoPath })}>
        <div className="search-bar-inner">
          {!isLg && <LogoPrimary />}

          <RebookingBanner />

          {!isRebookingSearch && (
            <>
              <SearchOptions />
              <SearchBarFilter
                search={search}
                onSetSearchHotelRoomsType={(val) =>
                  dispatch(searchActions.setSearchHotelRoomsType(val))
                }
                currentRoomType={search.roomType}
              />
            </>
          )}

          <SearchBarRoute disabled={isRebookingSearch} />

          {isOnewaySearch(search) && (
            <div className={`search-bar-spacer is--medium`} />
          )}

          {(isRoundtripSearch(search) || isMultiCitySearch(search)) && <ToggleRouteButton />}

          {isRoundtripSearch(search) || isOnewaySearch(search) ? (
            <ToggleOnlyOutboundButton />
          ) : (
            <div className="search-bar-spacer is--medium" />
          )}

          <SearchBarDateTime
            disableToggleButton={isRebookingSearch}
            disableDepDatePicker={search?.rebooking?.originalFareDirection === DIRECTION.INBOUND}
            disableArrDatePicker={search?.rebooking?.originalFareDirection === DIRECTION.OUTWARD}
          />
          <SearchBarPassengers
            searchPassengers={searchPassengers}
            isCurrentUserAdmin={isCurrentUserAdmin}
            isCurrentUserManager={isCurrentUserManager}
            isCurrentUserTravelAssistant={isCurrentUserTravelAssistant}
            organizationId={profile.organizationId}
            disabled={isRebookingSearch}
          />
          <div className="search-bar-button">
            <SearchSubmitButton onStartSearch={onStartSearch} isLoading={isSearchButtonLoading} />
          </div>
        </div>
        <div className="search-bar-concierge">
          <SearchBarConcierge profile={profile} />
        </div>
      </aside>

      <HotelWarningModal
        isOpen={showHotelWarning}
        onConfirm={handleStartSearch}
        onClose={() => setShowHotelWarning(false)}
      />
    </>
  );
};

export default SearchBarConn;
