import * as React from 'react';
import { useSelector } from 'react-redux';

// Utils
import { mediaBP } from '@toolkit/util/style';
import { useRebookingSearch } from '@src/hooks';
// Constants
// Actions
// Models, Stores & Interfaces
import { IRootState } from '@src/store';
import { selectors } from '@src/shared/src';
// Components
import { SearchBarConn } from '@pod/search';
import { SearchMap } from '@pod/search/components';
// Styles
import '../styles/Search.scss';

const SearchConn: React.FC = () => {
  const search = useSelector((state: IRootState) => selectors.search.search(state.search));

  useRebookingSearch(search);

  return (
    <div id="map-container">
      {mediaBP('md') && <SearchMap search={search} />}
      <SearchBarConn />
    </div>
  );
};
export default SearchConn;
