import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Utils
import { searchActions } from '@src/shared/src/actions';
import { searchSelectors } from '@src/shared/src/selectors';
// Constants
import { SEARCH_TYPE } from '@src/shared/src/const/app';
// Models & Interfaces
import { SearchSectionModel } from '@src/shared/src/models';
import { IRootState } from '@src/store';
// Components
import { Link } from '@toolkit/ui';
import { isOnewaySearch } from '@src/shared/src/util/search';

const ToggleOnlyOutboundButton: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const search = useSelector((state: IRootState) => searchSelectors.currentSearch(state));

  const setSearchTypeRoundtrip = () => {
    dispatch(searchActions.setSearchType(SEARCH_TYPE.ALL));
    dispatch(
      searchActions.setSearchSections([
        search.sections[0],
        new SearchSectionModel({
          depLat: search.sections[0].arrLat,
          depLng: search.sections[0].arrLng,
          depName: search.sections[0].arrName,
          depLocType: search.sections[0].arrLocType,
          arrLat: search.sections[0].depLat,
          arrLng: search.sections[0].depLng,
          arrName: search.sections[0].depName,
          arrLocType: search.sections[0].depLocType,
          depAt: search.sections[1]?.depAt, // keep selected date if exists
        }),
      ]),
    );
  };

  const setSearchTypeOneWay = () => {
    dispatch(searchActions.setSearchType(SEARCH_TYPE.OUTBOUND));
    dispatch(searchActions.setSearchSections([search.sections[0]]));
  };

  if (isOnewaySearch(search)) {
    return (
      <Link className="search-bar-onlylink" small onClick={setSearchTypeRoundtrip}>
        {t('search.bar.form.button.inbound')}
      </Link>
    );
  }

  return (
    <Link className="search-bar-onlylink" small onClick={setSearchTypeOneWay}>
      {t('search.bar.form.button.only_outbound')}
    </Link>
  );
};

export default ToggleOnlyOutboundButton;
