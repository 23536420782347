import * as React from 'react';
import Slider, { Range } from 'rc-slider';

// Utils
import {
  minutesToHoursAndMinutes,
  formatCents,
  isNilOrEmpty,
  formatDayDifference,
  partialRight,
} from '@src/shared/src/util/general';
import { formatDate } from '@src/shared/src/util/date';
import { tripSelectors } from '@src/shared/src/selectors';
import { t } from '@toolkit/util/i18n';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

// Constants
import { CENTS_CONVERSION_TYPES, DATE_FORMAT_TYPES, DIRECTION } from '@src/shared/src/const/app';
// Actions, Models & Interfaces
import { filterActions } from '@src/shared/src/actions';
import {
  BasketModel,
} from '@src/shared/src/models';
import { IRootState } from '@src/store';
import { appSettingsSelectors, filterSelectors } from '@src/shared/src/selectors';
import { selectors } from '@src/shared/src';

// Components
import { TransportationFilter, StationsFilter, BaseFilter, SupplierFilter } from '@pod/filters';
import { TripShuttleTypeSelector, TripSideBarSelectionInfo } from '@pod/trips/components';
import { SearchSideBarInfo } from '@pod/search/components';
import { Sidebar } from '@toolkit/ui';
// Styles

type Props = {
  isTripsLoading: boolean;
  isOpen?: boolean;
  direction: string;
  onChangeShuttleType: (vehicle: string, depOrArr: string) => void;
};

const getShuttleType = (isDep: boolean, direction: string, basket: BasketModel) => {
  if (isDep) {
    return direction === DIRECTION.OUTWARD
      ? basket.outwardShuttleAtDeparture
      : basket.inboundShuttleAtDeparture;
  } else {
    return direction === DIRECTION.OUTWARD
      ? basket.outwardShuttleAtArrival
      : basket.inboundShuttleAtArrival;
  }
};

const TripSideBarConn: React.FC<Props> = (props) => {
  const {
    onChangeShuttleType,
    isTripsLoading,
    direction,
    isOpen,
  } = props;

  const dispatch = useDispatch();

  const tripDurationFilter = useSelector((state: IRootState) => state.filters.tripDurationFilter);
  const tripArrTimeFilter = useSelector((state: IRootState) => filterSelectors.tripArrTimeFilter(state));
  const tripDepTimeFilter = useSelector((state: IRootState) => filterSelectors.tripDepTimeFilter(state));
  const tripPriceFilter = useSelector((state: IRootState) => state.filters.tripPriceFilter);
  const tripStopsFilter = useSelector((state: IRootState) => state.filters.tripStopsFilter);
  const tripTransportationFilter = useSelector((state: IRootState) => state.filters.tripTransportationFilter);
  const tripSupplierFilter = useSelector((state: IRootState) => state.filters.tripSupplierFilter);
  const tripStationsFilter = useSelector((state: IRootState) => state.filters.tripStationsFilter);
  const selectedOutbound = useSelector((state: IRootState) => selectors.checkout.outwardTrip(state.checkout));
  const cheapestInbound = useSelector((state: IRootState) => tripSelectors.cheapestInbound(state.trips));
  const search = useSelector((state: IRootState) => selectors.search.search(state.search));
  const loyaltyCards = useSelector((state: IRootState) => appSettingsSelectors.loyaltyCards(state));
  const basket = useSelector((state: IRootState) => state.checkout.basket);

  const formatTimeValue = (value: number) => (
    <span>
      {formatDate(value, DATE_FORMAT_TYPES.SHORT_TIME)}
      <sup>
        {formatDayDifference(
          new Date(value),
          direction === DIRECTION.OUTWARD ? search.depAt : search.arrAt,
        )}
      </sup>
    </span>
  );

  const onChangeShuttleTypeArrival = (vehicle: string) => onChangeShuttleType(vehicle, 'arrival');
  const onChangeShuttleTypeDeparture = (vehicle: string) => onChangeShuttleType(vehicle, 'departure');

  const onChangeSupplierFilter = (suppliers) => {
    dispatch(
      filterActions.setTripSupplierFilter({
        direction: direction,
        constraint: suppliers,
        onlyUpdateConstraint: false,
      }),
    );
  };

  const onChangeStationsFilter = (depStations, arrStations) => {
    dispatch(
      filterActions.setTripStationsFilter({
        direction: direction,
        constraint: {
          depStations,
          arrStations,
        },
        onlyUpdateConstraint: false,
      }),
    );
  };

  const onChangeTransportationFilter = (vehicles) => {
    dispatch(
      filterActions.setTripTransportationFilter({
        direction: direction,
        constraint: vehicles,
        onlyUpdateConstraint: false,
      }),
    );
  };

  const onChangeArrTimeFilter = (val) => {
    dispatch(
      filterActions.setTripArrTimeFilter({
        direction: direction,
        constraint: {
          min: tripArrTimeFilter.min,
          max: tripArrTimeFilter.max,
          currentMin: val[0],
          currentMax: val[1],
        },
        onlyUpdateConstraint: false,
      }),
    );
  };

  const onChangeDepTimeFilter = (val) => {
    dispatch(
      filterActions.setTripDepTimeFilter({
        direction: direction,
        constraint: {
          min: tripDepTimeFilter.min,
          max: tripDepTimeFilter.max,
          currentMin: val[0],
          currentMax: val[1],
        },
        onlyUpdateConstraint: false,
      }),
    );
  };

  const onChangePriceFilter = (value) => {
    dispatch(
      filterActions.setTripPriceFilter({
        direction: direction,
        constraint: {
          min: tripPriceFilter.min,
          max: tripPriceFilter.max,
          current: value[0],
        },
        onlyUpdateConstraint: false,
      }),
    );
  };

  const onChangeStopsFilter = (value) => {
    dispatch(
      filterActions.setTripStopsFilter({
        direction: direction,
        constraint: {
          min: tripStopsFilter.min,
          max: tripStopsFilter.max,
          current: value[0],
        },
        onlyUpdateConstraint: false,
      }),
    );
  };

  const onChangeDurationFilter = (value) => {
    dispatch(
      filterActions.setTripDurationFilter({
        direction: direction,
        constraint: {
          min: tripDurationFilter.min,
          max: tripDurationFilter.max,
          current: value[0],
        },
        onlyUpdateConstraint: false,
      }),
    );
  };

  return (
    <Sidebar isOpen={isOpen}>
      <SearchSideBarInfo search={search} loyaltyCards={loyaltyCards} />
      {!isNilOrEmpty(selectedOutbound) && direction === DIRECTION.INBOUND && (
        <TripSideBarSelectionInfo
          searchId={search.id}
          outward={selectedOutbound}
          cheapestInbound={cheapestInbound}
        />
      )}
      <div className="sidebar-inner">
        <TripShuttleTypeSelector
          key={`shuttle-filter-${isTripsLoading}`}
          departureShuttle={basket ? getShuttleType(true, direction, basket) : 'none'}
          arrivalShuttle={basket ? getShuttleType(false, direction, basket) : 'none'}
          onSelectArr={onChangeShuttleTypeArrival}
          onSelectDep={onChangeShuttleTypeDeparture}
          isOpen={!isTripsLoading}
        />
        <BaseFilter
          key={`duration-filter-${isTripsLoading}`}
          title={t('tripSideBar.durationFilter.title')}
          min={tripDurationFilter.min}
          max={tripDurationFilter.max}
          currentMin={tripDurationFilter.min}
          currentMax={tripDurationFilter.current}
          edgeFormatValue={minutesToHoursAndMinutes}
          midFormatValue={minutesToHoursAndMinutes}
          isOpen={!isTripsLoading}
          render={({ currentMax, currentMin, min, max, onChange }) => (
            <Slider value={currentMax} min={min} max={max} onChange={(val) => onChange([val])} />
          )}
          onChange={onChangeDurationFilter}
        />
        <BaseFilter
          key={`stops-filter-${isTripsLoading}`}
          title={t('tripSideBar.stopFilter.title')}
          min={tripStopsFilter.min}
          max={tripStopsFilter.max}
          currentMin={tripStopsFilter.min}
          currentMax={tripStopsFilter.current}
          isOpen={!isTripsLoading}
          render={({ currentMax, currentMin, min, max, onChange }) => (
            <Slider value={currentMax} min={min} max={max} onChange={(val) => onChange([val])} />
          )}
          onChange={onChangeStopsFilter}
        />
        <BaseFilter
          key={`price-filter-${isTripsLoading}`}
          title={t('tripSideBar.priceFilter.title')}
          min={tripPriceFilter.min}
          max={tripPriceFilter.max}
          currentMin={tripPriceFilter.min}
          currentMax={tripPriceFilter.current}
          edgeFormatValue={partialRight(formatCents, [CENTS_CONVERSION_TYPES.FLOOR])}
          midFormatValue={partialRight(formatCents, [CENTS_CONVERSION_TYPES.FLOOR])}
          isOpen={!isTripsLoading}
          render={({ currentMax, currentMin, min, max, onChange }) => (
            <Slider value={currentMax} min={min} max={max} onChange={(val) => onChange([val])} />
          )}
          onChange={onChangePriceFilter}
        />
        <BaseFilter
          key={`depTime-filter-${isTripsLoading}`}
          title={t('tripSideBar.departureTimeFilter.title')}
          min={tripDepTimeFilter.min}
          max={tripDepTimeFilter.max}
          currentMin={tripDepTimeFilter.currentMin}
          currentMax={tripDepTimeFilter.currentMax}
          edgeFormatValue={formatTimeValue}
          midFormatValue={formatTimeValue}
          isOpen={!isTripsLoading}
          render={({ currentMax, currentMin, min, max, onChange }) => (
            <Range value={[currentMin, currentMax]} min={min} max={max} onChange={onChange} />
          )}
          onChange={onChangeDepTimeFilter}
        />
        <BaseFilter
          key={`arrTime-filter-${isTripsLoading}`}
          title={t('tripSideBar.arrivalTimeFilter.title')}
          min={tripArrTimeFilter.min}
          max={tripArrTimeFilter.max}
          currentMin={tripArrTimeFilter.currentMin}
          currentMax={tripArrTimeFilter.currentMax}
          edgeFormatValue={formatTimeValue}
          midFormatValue={formatTimeValue}
          isOpen={!isTripsLoading}
          render={({ currentMax, currentMin, min, max, onChange }) => (
            <Range value={[currentMin, currentMax]} min={min} max={max} onChange={onChange} />
          )}
          onChange={onChangeArrTimeFilter}
        />
        <TransportationFilter
          key={`transportation-filter-${isTripsLoading}`}
          title={t('tripSideBar.transportationFilter.title')}
          vehicles={tripTransportationFilter}
          isOpen={!isTripsLoading}
          onChange={onChangeTransportationFilter}
        />
        <SupplierFilter
          key={`suppplier-filter-${isTripsLoading}`}
          title={t('tripSideBar.supplierFilter.title')}
          suppliers={tripSupplierFilter}
          isOpen={!isTripsLoading}
          onChange={onChangeSupplierFilter}
        />
        <StationsFilter
          key={`stations-filter-${isTripsLoading}`}
          title={t('tripSideBar.stationsFilter.title')}
          depStations={tripStationsFilter.depStations}
          arrStations={tripStationsFilter.arrStations}
          isOpen={!isTripsLoading}
          onChange={onChangeStationsFilter}
        />
      </div>
    </Sidebar>
  );
};

export default TripSideBarConn;
