import { createSelector } from 'reselect';
// Utils
import {
  filter,
  isNotNil,
  pathOr,
  pipe,
  propSatisfies,
  uniq,
} from '../util/general';
// Constants
// Actions
// Models
import {
  CoordinatesModel,
  SearchModel,
  AccountingInvoiceProfileModel,
} from '../models';
// Interfaces
import { ISearchState, ICheckoutState } from '../reducers';
import { RootState } from '../interfaces';
// Constants
import {
  MIN_LONG_HAUL_DISTANCE,
  ROLES,
} from '../const/app';

const getWarnings = pipe(
  filter(propSatisfies(isNotNil, 'lastErrorStatus')),
  uniq
);

export const transportSearches = (state:RootState) => state.search.transportSearches;
export const hotelSearches = (state:RootState) => state.search.hotelSearches;
export const searchType = (state:RootState) => state.search.currentSearch.searchType;

export const searchId = (state:ISearchState) => state.currentSearch.id;
export const searchDep = (state:ISearchState) => state.currentSearch.depName;
export const searchArr = (state:ISearchState) => state.currentSearch.arrName;
export const searchDepAt = (state:ISearchState) => state.currentSearch.depAt;
export const searchArrAt = (state:ISearchState) => state.currentSearch.arrAt;
export const isSearchExpired = (state:ISearchState) => state.currentSearch.expired;
export const selectDepLat = (state:ISearchState) => state.currentSearch.depLat;
export const selectDepLng = (state:ISearchState) => state.currentSearch.depLng;
export const selectArrLat = (state:ISearchState) => state.currentSearch.arrLat;
export const selectArrLng = (state:ISearchState) => state.currentSearch.arrLng;

export const searchDepCoor = createSelector(selectDepLat, selectDepLng, (lat, lng) => {
  if (lat === -1) return undefined;
  return new CoordinatesModel(lat, lng);
});

export const searchArrCoor = createSelector(selectArrLat, selectArrLng, (lat, lng) => {
  if (lat === -1) return undefined;
  return new CoordinatesModel(lat, lng);
});

export const searchPassengers = (state:ISearchState) => state.currentSearch.passengers;

export const search = (state:ISearchState):SearchModel => state.currentSearch;

export const transportWarnings = createSelector(
  transportSearches, (transSearches) => getWarnings(transSearches));

export const hotelWarnings = createSelector(
  hotelSearches, (hotelSearches) => getWarnings(hotelSearches));

export const searchHotelRoomCount =
  (state:ISearchState):number => state.currentSearch.hotelRoomCount;

export const isLongHaulSearch = (state:ISearchState):boolean =>
  pathOr(0, ['currentSearch', 'distance'], state) > MIN_LONG_HAUL_DISTANCE;

export const guestPassengers = createSelector(
  [searchPassengers], (passengers) => passengers.filter((passenger) => passenger.role === ROLES.GUEST)
)

export const firstPassenger = createSelector(
  [searchPassengers], (passengers) => passengers.find((passenger) => passenger.isFirst)
)

export const currentSearch = (state:RootState) => state.search.currentSearch;

export const isRebookingSearch = (state:RootState) => !!state.search.currentSearch.rebooking;

// TODO: refactor this out from here into checkout
export const getInvoiceProfile = (
  searchState:ISearchState,
  checkoutState:ICheckoutState) => {
    if (checkoutState.currentBooking) {
      const mPassenger = firstPassenger(searchState);
      const accountingIP = mPassenger.accountingInvoiceProfiles.find(
        (aip:AccountingInvoiceProfileModel) => aip.id === checkoutState.currentBooking.accountingInvoiceProfileId);
      return accountingIP;
    } else {
      return null;
    }
  };
