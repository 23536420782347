import * as React from 'react';

// Utils
import { navigate } from '@toolkit/util/app';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  isMultiCitySearch,
  isHotelOnlySearch,
  isRoundtripSearch,
} from '@src/shared/src/util/search';
// Constants
// Models
import { SettingsModel, SearchModel, SearchSectionModel } from '@src/shared/src/models';
// Interfaces
import { IRootState } from '@src/store';
// Components
import { Button } from '@toolkit/ui';
import SearchSideBarPassengerInfo from './SearchSideBarPassengerInfo';
import SearchSideBarRouteInfo from './SearchSideBarRouteInfo';
// Styles

type Props = {
  search: SearchModel;
  loyaltyCards: SettingsModel[];
  hideResetButton?: boolean;
};

const SearchSideBarInfo: React.FC<Props> = ({ search, hideResetButton }) => {
  const { t } = useTranslation();
  const redeemingAllowance = useSelector((state: IRootState) => state.checkout.redeemingAllowance);
  const hideResetBtn = hideResetButton || redeemingAllowance;

  return (
    <div className="sidebar-inner hidden-xs-down">
      {isMultiCitySearch(search) ? (
        search.sections.map((section: SearchSectionModel, index: number) => (
          <SearchSideBarRouteInfo
            key={`section-${index}`}
            title={`${t('TripSideBarInfo.route')} ${index + 1}`}
            from={section.depName}
            to={section.arrName}
            depAt={section.depAt}
          />
        ))
      ) : (
        <SearchSideBarRouteInfo
          title={t('TripSideBarInfo.title')}
          from={isHotelOnlySearch(search) ? null : search.depName}
          to={search.arrName}
          depAt={search.depAt}
          arrAt={isRoundtripSearch(search) ? search.sections?.[1]?.depAt : search.arrAt}
        />
      )}

      <SearchSideBarPassengerInfo />
      {!hideResetBtn && (
        <Button onClick={() => navigate('/')} className="sidebar-reset button button-light">
          {t('TripSideBarInfo.button.reset')}
        </Button>
      )}
    </div>
  );
};

export default SearchSideBarInfo;
