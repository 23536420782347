import * as React from 'react';

// Utils
import {
  mightContainHotelSearch,
  containsTransportSearch,
  isHotelOnlySearch,
} from '@src/shared/src/util/search';
// Models
import { SearchModel } from '@src/shared/src/models';
// Components
import { SearchBarCabinClassSelector } from './SearchBarCabinClassSelector';
import { SearchBarRoomsSelector } from './SearchBarRoomsSelector';
import SearchBarVehicles from './SearchBarVehicles';
// Styles
import '../styles/SearchBarFilter.scss';

type Props = {
  search: SearchModel;
  onSetSearchHotelRoomsType: (userId: number) => void;
  currentRoomType: number;
};

const SearchBarFilter: React.FC<Props> = ({ search }) => {
  return (
    <div className="searchbar-filter">
      {containsTransportSearch(search) && (
        <>
          <SearchBarVehicles />
          <SearchBarCabinClassSelector />
        </>
      )}
      {mightContainHotelSearch(search) && (
        <>
          <SearchBarRoomsSelector alignRight={isHotelOnlySearch(search)} />
        </>
      )}
    </div>
  );
};

export default SearchBarFilter;
