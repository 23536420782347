import * as React from 'react';
import { useEffect, useState, useRef, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Utils
import { cond, hasEmptyValues } from '@src/shared/src/util/general';
import { baseErrorCond } from '@toolkit/util/app';
import useClickOutside from '@toolkit/util/useClickOutside';
// Constants
// Actions
import { searchActions } from '@src/shared/src/actions';
// Models
import { IRootState } from '@src/store';
import { CoordinatesModel, SearchNodeModel } from '@src/shared/src/models';
// Interfaces
// Components
import { TextField } from '@toolkit/ui';
import { PinIcon } from '@toolkit/ui/icons';
import { AppContext } from '@toolkit/util/AppContext';
import SearchBarSlideOut from './SearchBarSlideOut';
import SearchBarRouteSelector from './SearchBarRouteSelector';
import SearchBarSlideOutNavbar from './SearchBarSlideOutNavbar';
// Styles
import '../styles/SearchBarRouteInput.scss';

type Props = {
  name: string;
  value: string;
  setSearchRoute: (name: string, type: string, coords: CoordinatesModel) => void;
  placeholder: string;
  displayErrorMsg?: boolean;
  errorMessage?: string;
  disabled?: boolean;
};

const SearchBarRouteInput: React.FC<Props> = ({
  name,
  value,
  placeholder,
  displayErrorMsg,
  errorMessage,
  disabled,
  setSearchRoute,
}) => {
  const dispatch = useDispatch();
  const appContext = useContext(AppContext);
  const overlayInputRef = useRef<HTMLInputElement>(null);
  const [suggestions, setSuggestions] = useState<SearchNodeModel[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [slideOutVisible, setSlideOutVisible] = useState(false);
  const [displayError, setDisplayError] = useState(false);
  const outsideRef = useClickOutside(() => setSlideOutVisible(false));

  const currentUser = useSelector((state: IRootState) => state.adminUser.profile);

  useEffect(() => {
    if (slideOutVisible && overlayInputRef.current) overlayInputRef.current.focus();
  }, [slideOutVisible]);

  const onInputChanged = (term: string) => {
    setSearchTerm(term);
    setSearchRoute(term, '', new CoordinatesModel(-1, -1)); // Clear previous selection

    if (term.length) {
      dispatch(
        searchActions.fetchLocationSuggestionsAsync.request({
          term,
          onSuccess: setSuggestions,
          onError: cond(baseErrorCond),
        }),
      );
    }
  };

  const onInputFocused = () => setSlideOutVisible(true);

  const onInputBlured = () => setDisplayError(displayErrorMsg);

  const onSuggestionSelected = (suggestion: SearchNodeModel) => {
    const types = suggestion.types;
    const preferredType = types.includes('locality') ? 'locality' : types[0];

    dispatch(
      searchActions.fetchLocationDetailAsync.request({
        location: suggestion,
        onSuccess: (coords) => {
          setSearchRoute(suggestion.name, preferredType, coords);
          setSlideOutVisible(false);
        },
        onError: cond(baseErrorCond),
      }),
    );
  };

  const textInputMarkup = (extraProps: { ref: React.Ref<HTMLInputElement>; showIcon: boolean }) => (
    <TextField
      // ref={extraProps.ref}
      name={name}
      label={placeholder}
      value={value}
      onChange={onInputChanged}
      onFocus={onInputFocused}
      onBlur={onInputBlured}
      errorMsg={displayError && displayErrorMsg ? errorMessage : ''}
      icon={extraProps.showIcon ? <PinIcon /> : null}
      disabled={disabled}
      showClear
    />
  );

  return (
    <div ref={outsideRef}>
      {textInputMarkup({ ref: null, showIcon: true })}

      <SearchBarSlideOut isOpen={slideOutVisible} className="is--route">
        {!appContext.isMediaBPNotMobile && (
          <>
            <SearchBarSlideOutNavbar onClose={() => setSlideOutVisible(false)} hideConfirmButton />
            {textInputMarkup({ ref: overlayInputRef, showIcon: false })}
          </>
        )}
        <SearchBarRouteSelector
          searchStr={searchTerm}
          suggestions={suggestions}
          homeLocations={
            hasEmptyValues(currentUser?.preference?.locationHome)
              ? null
              : currentUser.preference.locationHome
          }
          workLocations={
            hasEmptyValues(currentUser?.preference?.locationWork)
              ? null
              : currentUser.preference.locationWork
          }
          favoriteLocations={currentUser.preference.locationFavorite}
          onSuggestionSelected={onSuggestionSelected}
          profileId={currentUser.id}
        />
      </SearchBarSlideOut>
    </div>
  );
};
export default SearchBarRouteInput;
