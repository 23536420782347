import * as React from 'react';
import '../styles/SearchBarSlideOut.scss';

type Props = React.PropsWithChildren<{
  isOpen: boolean;
  className?: string;
}>;

const SearchBarSlideOut: React.FC<Props> = ({ isOpen, className, children }) => (
  <aside className={`searchbar-slide-out ${isOpen ? 'is--open' : ''} ${className}`}>
    {children}
  </aside>
);

export default SearchBarSlideOut;
