import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Utils
import {
  isHotelOnlySearch,
  isRentalOnlySearch,
  containsTransportSearch,
} from '@src/shared/src/util/search';
// Constants
import { SEARCH_TYPE } from '@src/shared/src/const/app';
// Actions
import {
  setSearchType,
  setSearchSections,
  setSearchDep,
  setSearchDepCoor,
  setSearchArr,
  setSearchArrCoor,
} from '@src/shared/src/actions/searchActions';
// Models
import { IRootState } from '@src/store';
import { SearchSectionModel } from '@src/shared/src/models';
// Components
import { Tab } from '@toolkit/ui';
// Styles
import '../styles/SearchBarOptions.scss';

export const SearchOptions: React.FC = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const search = useSelector((state: IRootState) => state.search.currentSearch);
  const rentalEnabled = useSelector((state: IRootState) => state.organization?.org?.rentalEnabled);

  const setSearchTypeAll = () => {
    dispatch(setSearchType(SEARCH_TYPE.ALL));
    dispatch(setSearchSections([new SearchSectionModel(), new SearchSectionModel()]));
  };

  const setSearchTypeHotel = () => {
    dispatch(setSearchType(SEARCH_TYPE.HOTEL));
    dispatch(setSearchSections([]));
  };

  const setSearchTypeRental = () => {
    dispatch(setSearchType(SEARCH_TYPE.RENTAL));
    dispatch(setSearchDep(''));
    dispatch(setSearchDepCoor(-1, -1));
    dispatch(setSearchArr(''));
    dispatch(setSearchArrCoor(-1, -1));
    dispatch(setSearchSections([]));
  };

  return (
    <div className="search-bar-options">
      <Tab
        label={t('search.bar.option.label.all_in_one')}
        onClick={setSearchTypeAll}
        active={containsTransportSearch(search)}
      />
      <Tab
        label={t('search.bar.option.label.hotel_only')}
        onClick={setSearchTypeHotel}
        active={isHotelOnlySearch(search)}
      />
      {rentalEnabled && (
        <Tab
          label={t('search.bar.option.label.rental_car')}
          onClick={setSearchTypeRental}
          active={isRentalOnlySearch(search)}
        />
      )}
    </div>
  );
};
