import { from, combineLatest, Observable } from 'rxjs';
import { map, distinctUntilChanged } from 'rxjs/operators';
import { isSameDay, isBefore, subDays } from 'date-fns';

// Utils
import { path, equals, isNil } from '@src/shared/src/util/general';
import { IRootState } from '@src/store';
import { store } from '@src/store/store.config';
import { getStateStreamSlice } from '@toolkit/util/state-utils/state.util';
import { ISearchState } from '@src/shared/src/reducers/searchReducer';
import { errorLogger } from '@src/shared/src/util/errors';
// Constants
import { SEARCH_TYPE, ROLES, DIRECTION } from '@src/shared/src/const/app';
// Actions
// Models
import { PassengerModel, RebookingModel, SearchSectionModel } from '@src/shared/src/models';
// Subscriptions
import * as userSubscriptions from '../user/user.subscriptions';

const _search$: Observable<ISearchState> = from(store as any).pipe(
  map((state: IRootState) => state.search),
);
const _searchType$ = getStateStreamSlice<ISearchState, SEARCH_TYPE>(_search$, [
  'currentSearch',
  'searchType',
]);
const _depLat$ = getStateStreamSlice<ISearchState, number>(_search$, ['currentSearch', 'depLat']);
const _depLng$ = getStateStreamSlice<ISearchState, number>(_search$, ['currentSearch', 'depLng']);
const _arrLat$ = getStateStreamSlice<ISearchState, number>(_search$, ['currentSearch', 'arrLat']);
const _arrLng$ = getStateStreamSlice<ISearchState, number>(_search$, ['currentSearch', 'arrLng']);
const _rentalReturnAtPickup$ = getStateStreamSlice<ISearchState, boolean>(_search$, [
  'currentSearch',
  'rentalReturnAtPickup',
]);
const _depAt$ = getStateStreamSlice<ISearchState, Date>(_search$, ['currentSearch', 'depAt']);
const _arrAt$ = getStateStreamSlice<ISearchState, Date>(_search$, ['currentSearch', 'arrAt']);
const _depRentalTime$ = getStateStreamSlice<ISearchState, string>(_search$, [
  'currentSearch',
  'depRentalTime',
]);
const _arrRentalTime$ = getStateStreamSlice<ISearchState, string>(_search$, [
  'currentSearch',
  'arrRentalTime',
]);
const _passengers$ = getStateStreamSlice<ISearchState, PassengerModel[]>(_search$, [
  'currentSearch',
  'passengers',
]);
const _rebooking$ = getStateStreamSlice<ISearchState, RebookingModel>(_search$, [
  'currentSearch',
  'rebooking',
]);
const _searchSections$ = getStateStreamSlice<ISearchState, SearchSectionModel[]>(_search$, [
  'currentSearch',
  'sections',
]);

export const isDepMissing = () =>
  combineLatest(_searchSections$, _searchType$, _depLat$, _depLng$).pipe(
    map(([sections, searchType, depLat, depLng]) => {
      switch (searchType) {
        case SEARCH_TYPE.HOTEL:
          return false;
        case SEARCH_TYPE.RENTAL:
          return depLat === -1 || depLng === -1;
        default:
          return sections.some(({ depLat, depLng }) => depLat === -1 || depLng === -1);
      }
    }),
  );

export const isArrMissing = () =>
  combineLatest(_searchSections$, _searchType$, _rentalReturnAtPickup$, _arrLat$, _arrLng$).pipe(
    map(([sections, searchType, rentalReturnAtPickup, arrLat, arrLng]) => {
      switch (searchType) {
        case SEARCH_TYPE.HOTEL:
          return arrLat === -1 || arrLng === -1;
        case SEARCH_TYPE.RENTAL:
          return !rentalReturnAtPickup && (arrLat === -1 || arrLng === -1);
        default:
          return sections.some((section) => section.arrLat === -1 || section.arrLng === -1);
      }
    }),
  );

export const isDepDateMissing = () =>
  combineLatest(_searchSections$, _searchType$, _depAt$, _depRentalTime$).pipe(
    map(([sections, searchType, depAt, depRentalTime]) => {
      switch (searchType) {
        case SEARCH_TYPE.HOTEL:
          return isNil(depAt);
        case SEARCH_TYPE.RENTAL:
          return isNil(depRentalTime) || isNil(depAt);
        default:
          return sections.some((section) => isNil(section.depAt));
      }
    }),
  );

export const isArrDateMissing = () =>
  combineLatest(_searchType$, _arrAt$, _arrRentalTime$).pipe(
    map(([searchType, arrAt, arrRentalTime]) => {
      switch (searchType) {
        case SEARCH_TYPE.RENTAL:
          return isNil(arrRentalTime) || isNil(arrAt);
        case SEARCH_TYPE.HOTEL:
          return isNil(arrAt);
        default:
          return false;
      }
    }),
  );

export const isDepDateInPast = () =>
  combineLatest(_searchSections$, _depAt$, _rebooking$).pipe(
    map(([sections, depAt, rebooking]) => {
      if (isNil(rebooking)) return false; // TODO: is this correct for all sections?
      if (sections?.length)
        return sections.some((section) => isBefore(section.depAt, subDays(new Date(), 1)));
      return isBefore(depAt, subDays(new Date(), 1));
    }),
  );

export const isHotelSameDay = () =>
  combineLatest(_searchType$, _depAt$, _arrAt$).pipe(
    map(
      ([searchType, depAt, arrAt]) =>
        searchType === SEARCH_TYPE.HOTEL && isSameDay(depAt as Date, arrAt as Date),
    ),
  );

export const isDepArrSame = () =>
  combineLatest(_searchSections$, _searchType$, _depLat$, _depLng$, _arrLat$, _arrLng$).pipe(
    map(([sections, searchType, depLat, depLng, arrLat, arrLng]) => {
      switch (searchType) {
        case SEARCH_TYPE.HOTEL:
          return false;
        case SEARCH_TYPE.RENTAL:
          return false;
        default:
          return sections.some(
            (section) => section.depLat === section.arrLat && section.depLng === section.arrLng,
          );
      }
    }),
  );

export const isRentalDriverCountWrong = () =>
  combineLatest(_searchType$, _passengers$).pipe(
    map(
      ([searchType, passengers]) =>
        searchType === SEARCH_TYPE.RENTAL && (passengers as PassengerModel[]).length !== 1,
    ),
  );

export const isRentalDepartureTimeInPast = () =>
  combineLatest(_searchType$, _depAt$, _depRentalTime$).pipe(
    map(([searchType, depAt, depRentalTime]) => {
      if (searchType === SEARCH_TYPE.RENTAL && isSameDay(depAt as Date, new Date())) {
        const [hours, minutes] = depRentalTime.split(':').map(Number);
        depAt.setHours(hours, minutes);
        return isBefore(depAt, new Date());
      }
      return false;
    }),
  );
export const isPassengersMissing = () =>
  _passengers$.pipe(map((passengers: PassengerModel[]) => passengers.length < 1));

export const isPassengersSelectionWrong = () =>
  combineLatest(
    _passengers$,
    userSubscriptions.loggedInUserId(),
    userSubscriptions.canRemoveItselfAsPassenger(),
  ).pipe(
    map(([passengers, loggedInUserId, canRemoveItselfAsPassenger]) =>
      canRemoveItselfAsPassenger
        ? false
        : !(passengers as PassengerModel[]).find((pass) => pass.userId === loggedInUserId),
    ),
  );

export const isPassengersMoreThanAllowed = () =>
  _passengers$.pipe(map((passengers) => passengers.length > 5));

export const isSearchValid = () =>
  combineLatest(
    isDepMissing(),
    isArrDateMissing(),
    isArrMissing(),
    isHotelSameDay(),
    isDepDateMissing(),
    isDepArrSame(),
    isDepDateInPast(),
    isRentalDriverCountWrong(),
    isPassengersMissing(),
    isDropoffBeforePickup(),
    isRentalDepartureTimeInPast(),
    isPassengersSelectionWrong(),
    isPassengersMoreThanAllowed(),
  ).pipe(map((values) => values.every((val) => val === false)));

export const isOnlyPassengerGuest = () =>
  _passengers$.pipe(
    map(
      (passengers: PassengerModel[]) =>
        passengers.length === 1 && passengers[0].role === ROLES.GUEST,
    ),
  );

export const firstPassenger = (): Observable<PassengerModel> =>
  _passengers$.pipe(
    map((passengers: PassengerModel[]) => {
      const firstPassenger = passengers.find((passenger) => passenger.isFirst);
      if (isNil(firstPassenger)) {
        if (process.env.NODE_ENV !== 'test') {
          errorLogger('Could not find firstPassenger', JSON.stringify(passengers));
        }
        return undefined;
      } else {
        return firstPassenger;
      }
    }),
  );

export const getSubscription = (statePath: string[]) =>
  from(store as any).pipe(
    map((state: IRootState) => (statePath ? path(statePath, state) : state.search)),
    distinctUntilChanged(equals),
  );

export const searchDate = (direction: DIRECTION) =>
  combineLatest(_depAt$, _arrAt$).pipe(
    map(([depAt, arrAt]) => {
      switch (direction) {
        case DIRECTION.OUTWARD:
          return depAt;
        case DIRECTION.INBOUND:
          return arrAt;
      }
    }),
  );

export const isDropoffBeforePickup = () =>
  combineLatest(_depAt$, _arrAt$, _depRentalTime$, _arrRentalTime$, _searchType$).pipe(
    map(
      ([depAt, arrAt, depRentalTime, arrRentalTime, searchType]) =>
        searchType === SEARCH_TYPE.RENTAL &&
        isSameDay(depAt as Date, arrAt as Date) &&
        depRentalTime > arrRentalTime,
    ),
  );
