import { SVGProps } from 'react';
import * as React from 'react';

export const TicketIcon = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    {...props}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_704_8612" maskUnits="userSpaceOnUse" x="0" y="0" width="16" height="16">
      <rect width="16" height="16" fill="white" />
    </mask>
    <g mask="url(#mask0_704_8612)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.113 4.41843C14.9174 5.20493 14.9408 6.49641 14.1655 7.30885L7.88489 13.89L7.77855 13.7842C7.97517 13.6208 8.14067 13.4133 8.25878 13.1675L11.9401 5.50624C12.3813 4.58818 11.9941 3.484 11.0765 3.04328L9.63179 2.34942C10.3478 1.7497 11.414 1.77953 12.0984 2.44871L14.113 4.41843ZM4.83875 2.45439C5.27762 1.54115 6.37073 1.15714 7.28355 1.59553L10.3026 3.04542C11.2202 3.48612 11.6074 4.5903 11.1662 5.50836L7.48425 13.1701C7.04538 14.0834 5.95227 14.4674 5.03945 14.029L2.02042 12.5791C1.10277 12.1384 0.715581 11.0342 1.15677 10.1162L4.83875 2.45439ZM9.97594 4.29586L6.49915 2.67089L6.36168 2.97887L9.83846 4.60385L9.97594 4.29586ZM6.76284 5.15837L4.96841 8.91771L4.66496 8.78321L6.45938 5.02388L6.76284 5.15837ZM5.76962 9.29387L6.911 6.88555L6.60781 6.75079L5.46644 9.15911L5.76962 9.29387ZM5.19823 5.59143L5.50151 5.7261L4.16734 8.54157L3.86406 8.4069L5.19823 5.59143ZM3.71021 12.1657C4.17103 12.3878 4.72443 12.191 4.94157 11.7278L5.72393 10.0587L3.26988 8.87568L2.09313 11.3861L3.71021 12.1657Z"
        fill="#0172B1"
      />
    </g>
  </svg>
);
