import * as React from 'react';

// Utils
import { useTranslation } from 'react-i18next';
// Constants
// Actions
// Models
// Interfaces
// Components
import { Button, Modal } from '@toolkit/ui';
import { HotelWarningIcon } from '@toolkit/ui/icons';
// Styles
import 'react-responsive-modal/styles.css';
import '../styles/HotelWarningModal.scss';

type Props = {
  isOpen: boolean;
  onConfirm: () => void;
  onClose: () => void;
};

const HotelWarningModal: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  return (
    <Modal
      isOpen={props.isOpen}
      showCloseIcon={false}
      closeOnOverlayClick={false}
      closeOnEsc={false}
      onClose={props.onClose}
      classNames={{
        root: 'hotel-warning-modal',
        overlay: 'hotel-warning-modal-overly',
        modal: 'hotel-warning-modal-modal',
      }}>
      <HotelWarningIcon />
      <div className="hotel-warning-modal-title">{t('hotelWarningModal.title')}</div>
      {t('hotelWarningModal.content')}
      <div className="hotel-warning-modal-buttons">
        <Button onClick={props.onConfirm}>{t('hotelWarningModal.continue')}</Button>
        <Button onClick={props.onClose} className="button button-light">
          {t('hotelWarningModal.abort')}
        </Button>
      </div>
    </Modal>
  );
};
export default HotelWarningModal;
