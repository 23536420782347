import { isSameDay, startOfDay } from 'date-fns';

// Utils
// Constants
import { SEARCH_TYPE } from '../const/app';
// Actions
// Models
import { SearchModel } from '../models';

export const containsTransportSearch = (search: SearchModel) =>
  search.searchType === SEARCH_TYPE.OUTBOUND ||
  search.searchType === SEARCH_TYPE.ALL ||
  search.searchType === SEARCH_TYPE.MULTICITY;

export const mightContainHotelSearch = (search: SearchModel) =>
  search.searchType === SEARCH_TYPE.HOTEL ||
  search.searchType === SEARCH_TYPE.ALL ||
  search.searchType === SEARCH_TYPE.MULTICITY;

// A -> B
export const isOnewaySearch = (search: SearchModel) => search.searchType === SEARCH_TYPE.OUTBOUND;

// A -> B -> A
export const isRoundtripSearch = (search: SearchModel) => search.searchType === SEARCH_TYPE.ALL;

// A -> B, B/C -> D
export const isMultiCitySearch = (search: SearchModel) =>
  search.searchType === SEARCH_TYPE.MULTICITY;

export const isRebookingSearch = (search: SearchModel) => !!search.rebooking;

export const sectionsOnSameDay = (search: SearchModel) => {
  const depAt = search.sections?.[0]?.depAt;
  const returnAt = search.sections?.[1]?.depAt;

  return isSameDay(depAt, returnAt);
};

export const sectionsArrAndDepMatch = (search: SearchModel) =>
  search.sections?.[0]?.arrName === search.sections?.[1]?.depName;

/**
 * Determines if the search should include hotels based on the search type and the locations of the sections.
 */
export const shouldSearchForHotels = (search: SearchModel) => {
  // we can only rebook transport
  if (isRebookingSearch(search)) return false;
  if (isHotelOnlySearch(search)) return true;

  // if departure and return are on the same day, we don't need hotels
  if (isRoundtripSearch(search)) return !sectionsOnSameDay(search);

  // we only need hotels if the sections are from the same location and not on the same day
  if (isMultiCitySearch(search)) return !sectionsOnSameDay(search) && sectionsArrAndDepMatch(search);

  return false;
};

export const isHotelOnlySearch = (search: SearchModel) => search.searchType === SEARCH_TYPE.HOTEL;

export const isRentalOnlySearch = (search: SearchModel) => search.searchType === SEARCH_TYPE.RENTAL;

/**
 * Returns an array of search types 'transport', 'hotel', 'rental' based on the search.searchType enum
 */
export const getSearchTypesArray = (search: SearchModel) => {
  switch (search.searchType) {
    case SEARCH_TYPE.ALL:
    case SEARCH_TYPE.MULTICITY:
      const types = ['transport'];
      if (shouldSearchForHotels(search)) types.push('hotel');
      return types;
    case SEARCH_TYPE.HOTEL:
      return ['hotel'];
    case SEARCH_TYPE.RENTAL:
      return ['rental'];
    case SEARCH_TYPE.OUTBOUND:
      return ['transport'];
    default:
      throw new Error('Invalid search type');
  }
};

const getSearchLocationCount = (search: SearchModel) => {
  const locations = new Set();
  search.sections.forEach((section) => {
    locations.add(section.depName);
    locations.add(section.arrName);
  });

  return locations.size;
};

/**
 * Determines the searchType enum based on the search types array
 * @param search The search model'
 * @returns The SEARCH_TYPE enum
 */
export const determineSearchType = (search: SearchModel) => {
  if (search.types.length === 1 && search.types[0] === 'hotel') return SEARCH_TYPE.HOTEL;
  if (search.types.length === 1 && search.types[0] === 'rental') return SEARCH_TYPE.RENTAL;

  if (search.types.includes('transport')) {
    if (search.sections.length === 1) return SEARCH_TYPE.OUTBOUND;
    else if (getSearchLocationCount(search) === 2) return SEARCH_TYPE.ALL;
    else return SEARCH_TYPE.MULTICITY;
  }
};

/**
 * Returns the minimum dep date for a section based on the previous section's arrival date.
 */
export const getSectionMinDate = (search: SearchModel, sectionIndex: number) => {
  if (sectionIndex === 0) return startOfDay(new Date());
  return search.sections[sectionIndex - 1].depAt;
};

export const isRentalAndPickupSame = (search: SearchModel) =>
  isRentalOnlySearch(search) && search.rentalReturnAtPickup;
