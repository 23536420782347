import * as React from 'react';

// Utils
import { hashHistory, isCurrentPath } from '@toolkit/util/app';
import classnames from 'classnames';

// Actions, Models & Interfaces
import { UserModel, OrganizationModel } from '@src/shared/src/models';
// Components
import Navbar from './Navbar';
import Logo from './Logo';
import DemoBanner from '@toolkit/ui/DemoBanner';

// Styles
import '../styles/Header.scss';

type Props = {
  profile?: UserModel;
  organization?: OrganizationModel;
  isCurrentUserNotTraveller?: boolean;
  breadcrumbs: JSX.Element;
  onLogout: (val: string) => void;
};

type State = {
  currentPath: string;
  currentHash: string;
};

export default class Header extends React.PureComponent<Props, State> {
  private historyUnlisten: () => void;
  readonly state: State = {
    currentPath: hashHistory.location.pathname,
    currentHash: hashHistory.location.hash,
  };

  public componentDidMount() {
    this.historyUnlisten = hashHistory.listen((location: any) => {
      this.setState({ currentPath: location.pathname });
      this.setState({ currentHash: location.hash });
    });
  }

  public componentWillUnmount() {
    this.historyUnlisten();
  }

  isPathPastPrepare = () =>
    isCurrentPath('/booking/prepare') ||
    isCurrentPath('/booking/payment') ||
    isCurrentPath('/booking/confirm');

  isSearch = () => {
    return this.state.currentPath === '/' || this.state.currentPath === '/search/';
  };

  public render() {
    const { profile, organization, breadcrumbs, isCurrentUserNotTraveller } = this.props;
    return (
      <header className={classnames('tcp-header', { 'is--transparent': this.isSearch() })}>
        {organization?.isDemo && <DemoBanner />}
        <div className="tcp-header-row">
          {(!this.isSearch() || organization?.logoPath) && (
            <Logo
              language={profile?.preference?.language}
              orgLogoPath={organization?.logoPath}
              orgId={organization?.id}
            />
          )}
          {breadcrumbs}
          <Navbar
            currentUser={profile}
            currentOrganization={organization}
            isCurrentUserNotTraveller={isCurrentUserNotTraveller}
            onLogout={this.props.onLogout}
          />
        </div>
      </header>
    );
  }
}
