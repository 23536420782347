import * as React from 'react';

// Utils
import { useTranslation } from 'react-i18next';
import { searchHooks } from '@src/services';
// Constants
// Actions
// Models
// Interfaces
// Components
import { Button } from '@toolkit/ui';

type Props = {
  onStartSearch: () => void;
  isLoading: boolean;
};

export const SearchSubmitButton: React.FC<Props> = (props) => {
  const { t } = useTranslation();
  const isSearchValid = searchHooks.useIsSearchValid();
  return (
    <Button
      id="btnSearchTrip"
      className="button is--large"
      onClick={props.onStartSearch}
      disabled={!isSearchValid}
      isLoading={props.isLoading}>
      {t('search.bar.button')}
    </Button>
  );
};
