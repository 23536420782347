import * as React from 'react';
import { Trans } from 'react-i18next';
import { Field } from 'react-final-form';

// Utils
import { map, pipe, uniq } from '@src/shared/src/util/general';
import { checked } from '@toolkit/util/formsValidation';
import { t } from '@toolkit/util/i18n';
// Constants
import { TRAIN_OPERATORS } from '@src/shared/src/const/app';
// Actions
// Models
// Interfaces
// Components
import { FormCheckbox } from '@src/toolkit/ui';
// Styles
import '../styles/LegalNotice.scss';
import { VEHICLE_TYPES } from '@src/shared/src/const/app';

type Props = {
  operators: string[];
  vehicles: string[];
};

const getDBLegalNotice = () => {
  return (
    <li>
      <Trans i18nKey="checkoutPayment.text.legalNotice.deuscheBahnV2">
        <a
          href={t('checkoutPayment.legalNotice.link.db.generalTerms')}
          target="_blank"
          className="is-bold">
          generalTerms
        </a>
        <a
          href={t('checkoutPayment.legalNotice.link.db.regulations')}
          target="_blank"
          className="is-bold">
          contracting parties regulations
        </a>
        <a
          href={t('checkoutPayment.legalNotice.link.db.termOfUse')}
          target="_blank"
          className="is-bold">
          terms of use
        </a>
        <br />
      </Trans>
    </li>
  );
};

const getSNCFLegalNotice = () => {
  return (
    <li>
      <Trans i18nKey="checkoutPayment.text.legalNotice.sncf">
        <a
          href={t('checkoutPayment.legalNotice.link.sncf.generalTerms')}
          target="_blank"
          className="is-bold">
          generalTerms
        </a>
      </Trans>
    </li>
  );
};

const getSBBLegalNotice = () => {
  return (
    <li>
      <Trans i18nKey="checkoutPayment.text.legalNotice.sbb">
        <a
          href={t('checkoutPayment.legalNotice.link.sbb.generalTerms')}
          target="_blank"
          className="is-bold">
          generalTerms
        </a>
      </Trans>
    </li>
  );
};

const getATOCLegalNotice = () => {
  return (
    <li>
      <Trans i18nKey="checkoutPayment.text.legalNotice.atoc">
        <a
          href={t('checkoutPayment.legalNotice.link.atoc.generalTerms')}
          target="_blank"
          className="is-bold">
          generalTerms
        </a>
      </Trans>
    </li>
  );
};

const getWESTLegalNotice = () => {
  return (
    <li>
      <Trans i18nKey="checkoutPayment.text.legalNotice.west">
        <a
          href={t('checkoutPayment.legalNotice.link.west.generalTerms')}
          target="_blank"
          className="is-bold">
          generalTerms
        </a>
      </Trans>
    </li>
  );
};

const getTrainLegalNoticeByOperator = (operator: string) => {
  switch (operator) {
    case TRAIN_OPERATORS.DEUTSCHEBAHN:
      return getDBLegalNotice();

    case TRAIN_OPERATORS.SNCF:
    case TRAIN_OPERATORS.EUROSTAR:
    case TRAIN_OPERATORS.TGV_LYRIA:
    case TRAIN_OPERATORS.THALYS:
      return getSNCFLegalNotice();

    case TRAIN_OPERATORS.SBB:
    case TRAIN_OPERATORS.CFF:
    case TRAIN_OPERATORS.FFS:
      return getSBBLegalNotice();

    case TRAIN_OPERATORS.ATOC:
      return getATOCLegalNotice();

    case TRAIN_OPERATORS.WESTBAHN:
    case TRAIN_OPERATORS.WESTBAHN_TEST:
      return getWESTLegalNotice();
  }
};

const CheckoutPaymentLegalNoticeForm: React.FC<Props> = (props: Props) => (
  <div className="tcp-legal-notice">
    <h2 className="checkout-title">{t('checkoutPaymentInfo.label.legalNotice')}</h2>
    <Field
      id="accept"
      name="legalNotice"
      label={t('checkoutPaymentInfo.checkbox.label.acceptTerms')}
      labelCSS="is-bold"
      component={FormCheckbox}
      type="checkbox"
      wrapperCSS="tcp-legal-notice-terms"
      validate={checked}
    />
    <ul>
      <li>{t('checkoutPayment.text.legalNotice.tcp')}</li>

      {props.vehicles.includes(VEHICLE_TYPES.PLANE) && (
        <li>
          <Trans i18nKey="checkoutPayment.text.legalNotice.airlines">
            <a
              href="https://www.airlinecheckins.com/dangerous-goods"
              target="_blank"
              className="is-bold">
              link
            </a>
          </Trans>
        </li>
      )}

      {props.vehicles.includes(VEHICLE_TYPES.TRAIN) &&
        pipe(
          uniq,
          map(operator => <div key={operator}>{getTrainLegalNoticeByOperator(operator)}</div>),
        )(props.operators)}
    </ul>
  </div>
);

export default CheckoutPaymentLegalNoticeForm;
