import * as React from 'react';
import Select from 'react-select';
import classNames from 'classnames';

// Utils
import { navigate, baseErrorCond } from '@toolkit/util/app';
import { cond } from '@src/shared/src/util/general';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// Constants
import { ROUTES } from '@toolkit/const/app';
import { HOTEL_SORT_VALUES } from '@src/shared/src/const/app';
// Actions
import { filterActions, basketActions } from '@src/shared/src/actions';
// Models
import { OptionModel } from '@src/shared/src/models';
// Interfaces
import { IRootState } from '@src/store';

// Components
import SortBar from '@pod/trips/components/SortBar';
import useBreakpoints from '@toolkit/util/useBreakpoints';

// Props type
type Props = {
  searchId: number;
  isDisplayHotelList: boolean;
  isSearchingForOnlyHotel: boolean;
  onToggleDisplayList: () => void;
  filteredHotelsLen: number;
  totalHotelsLen: number;
  setHotelDetailsOpenedId: (val: string) => void;
};

const HotelsHeader: React.FC<Props> = ({
  searchId,
  isDisplayHotelList,
  isSearchingForOnlyHotel,
  onToggleDisplayList,
  filteredHotelsLen,
  totalHotelsLen,
  setHotelDetailsOpenedId,
}) => {
  const { t } = useTranslation();
  const { isMd } = useBreakpoints();
  const dispatch = useDispatch();
  const currentHotelSortingFilter = useSelector(
    (state: IRootState) => state.filters.hotelSortingFilter.current,
  );
  const basketHotelsLen = useSelector((state: IRootState) => state.checkout.hotels.length);

  const sortingOptions = (): OptionModel[] => [
    {
      label: t('HotelsConn.select.option.recommendationSort'),
      value: HOTEL_SORT_VALUES.RECOMMENDATION_SCORE,
    },
    { label: t('HotelsConn.select.option.starsSort'), value: HOTEL_SORT_VALUES.STARS },
    { label: t('HotelsConn.select.option.totalPriceSort'), value: HOTEL_SORT_VALUES.TOTAL_PRICE },
    { label: t('HotelsConn.select.option.ratingsSort'), value: HOTEL_SORT_VALUES.RATING },
    { label: t('HotelsConn.select.option.distanceSort'), value: HOTEL_SORT_VALUES.DISTANCE },
  ];

  const onChangeSortOption = (nOpt: OptionModel) => {
    setHotelDetailsOpenedId('');
    dispatch(
      filterActions.setHotelSortingFilter({
        constraint: { current: nOpt.value },
        onlyUpdateConstraint: false,
      }),
    );
  };

  const onNoHotelNeeded = () => {
    if (basketHotelsLen > 0) {
      dispatch(
        basketActions.removeItemFromBasketAsync.request({
          onSuccess: () => navigate(`${ROUTES.BOOKING.RESERVE}${searchId}`),
          onError: cond(baseErrorCond),
          itemType: 'hotel',
        }),
      );
    } else {
      navigate(`${ROUTES.BOOKING.RESERVE}${searchId}`);
    }
  };

  const headerSortStyle = classNames('content-header-sort', {
    'content-header-sort-separated': !isSearchingForOnlyHotel,
  });

  return (
    <div className="content-header content-header-hotel">
      <h2 className="content-title">
        {t('hotelsConn.header.title')}&nbsp;
        {t('global.results.label.of', {
          total: totalHotelsLen,
          filtered: filteredHotelsLen,
        })}
        {!isSearchingForOnlyHotel && isMd && (
          <button className="button button-light" onClick={onNoHotelNeeded}>
            {t('hotelSideBarInfo.checkbox.label.noHotelNeeded')}
          </button>
        )}
      </h2>
      <button className="content-header-show_map" onClick={onToggleDisplayList}>
        {isDisplayHotelList ? (
          <span>
            <i className="icon-to" />
            {t('HotelsConn.header.label.showMap')}
          </span>
        ) : (
          t('HotelsConn.header.label.showList')
        )}
      </button>
      {isMd ? (
        <SortBar
          options={sortingOptions()}
          value={currentHotelSortingFilter}
          onChange={onChangeSortOption}
        />
      ) : (
        <div className={headerSortStyle}>
          {t('HotelsConn.header.label.sort')}:
          <Select
            className="Select--trips"
            options={sortingOptions()}
            isClearable={false}
            value={currentHotelSortingFilter}
            onChange={onChangeSortOption}
          />
        </div>
      )}
      {!isSearchingForOnlyHotel && !isMd && (
        <button className="button button-light" onClick={onNoHotelNeeded}>
          {t('hotelSideBarInfo.checkbox.label.noHotelNeeded')}
        </button>
      )}
    </div>
  );
};

export default HotelsHeader;
