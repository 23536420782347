import * as React from 'react';
import { OverlayView } from '@react-google-maps/api';

// Utils
// Constants
// Actions, Models & Interfaces
import { CoordinatesModel } from '@src/shared/src/models';
// Components
// Styles
import '../styles/MapMarker.scss';
import { PinIcon } from '@toolkit/ui/icons';

type Props = {
  position: CoordinatesModel;
  locationLabel: string;
  dateLabel?: string;
};

const MapMarker: React.FC<Props> = ({ position, locationLabel, dateLabel = '' }) => (
  <OverlayView position={position} mapPaneName={OverlayView.FLOAT_PANE}>
    <div className="map-marker">
      <PinIcon />
      <div className="map-marker-box">
        {dateLabel && <div className="map-marker-date">{dateLabel}</div>}
        <div className="map-marker-location">{locationLabel}</div>
      </div>
    </div>
  </OverlayView>
);

export default MapMarker;
