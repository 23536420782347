import { SVGProps } from 'react';
import * as React from 'react';

export const PinRemoveIcon = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg {...props} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask
      id="mask0_2401_6226"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="16"
      height="16">
      <rect width="16" height="16" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_2401_6226)">
      <path
        d="M7.99996 8.0002C8.36663 8.0002 8.68051 7.86964 8.94163 7.60853C9.20274 7.34742 9.33329 7.03353 9.33329 6.66686C9.33329 6.3002 9.20274 5.98631 8.94163 5.7252C8.68051 5.46408 8.36663 5.33353 7.99996 5.33353C7.63329 5.33353 7.3194 5.46408 7.05829 5.7252C6.79718 5.98631 6.66663 6.3002 6.66663 6.66686C6.66663 7.03353 6.79718 7.34742 7.05829 7.60853C7.3194 7.86964 7.63329 8.0002 7.99996 8.0002ZM7.99996 14.6669C6.21107 13.1446 4.87496 11.7308 3.99163 10.4252C3.10829 9.11964 2.66663 7.91131 2.66663 6.8002C2.66663 5.13353 3.20274 3.80575 4.27496 2.81686C5.34718 1.82797 6.58885 1.33353 7.99996 1.33353C8.18885 1.33353 8.38329 1.34464 8.58329 1.36686C8.78329 1.38908 8.97774 1.41131 9.16663 1.43353L10.7666 3.03353L9.36663 4.43353L11.25 6.31686L12.65 4.91686L13.1666 5.4502C13.2111 5.67242 13.25 5.89464 13.2833 6.11686C13.3166 6.33908 13.3333 6.56686 13.3333 6.8002C13.3333 7.91131 12.8916 9.11964 12.0083 10.4252C11.125 11.7308 9.78885 13.1446 7.99996 14.6669ZM11.25 5.36686L10.3166 4.43353L11.7166 3.03353L10.3166 1.63353L11.25 0.700195L12.65 2.1002L14.05 0.700195L14.9833 1.63353L13.5833 3.03353L14.9833 4.43353L14.05 5.36686L12.65 3.96686L11.25 5.36686Z"
        fill="#2D67B2"
      />
    </g>
  </svg>
);
