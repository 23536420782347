import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Utils
import { cond } from '@src/shared/src/util/general';
import { clearLS } from '@toolkit/util/general';
import { baseErrorCond, redirectToBackendForLogin } from '@toolkit/util/app';
// Constants
// Actions
import { usersActions } from '@src/shared/src/actions';
// Models
// Interfaces
import { IRootState } from '@src/store';
// Components
import { Header, HeaderMobile, Breadcrumbs } from '@pod/header/components';
// Styles
import '../styles/Navbar.scss';
import '../styles/Intercom.scss';
import { adminUserSelectors } from '@src/shared/src/selectors';
import useBreakpoints from '@toolkit/util/useBreakpoints';

const HeaderConn: React.FC = () => {
  const dispatch = useDispatch();
  const { isLg } = useBreakpoints();

  const profile = useSelector((state: IRootState) => state.adminUser.profile);
  const organization = useSelector((state: IRootState) => state.organization.org);
  const isCurrentUserNotTraveller = useSelector((state: IRootState) =>
    adminUserSelectors.isCurrentUserNotTraveller(state.adminUser),
  );

  const onLogout = () => {
    dispatch(
      usersActions.doLogoutAsync.request({
        onSuccess: () => {
          clearLS();
          redirectToBackendForLogin();
        },
        onError: cond(baseErrorCond),
      }),
    );
  };

  // TODO: merge <HeaderMobile>, <Header> and <Navbar> components in here
  return (
    <div>
      {isLg ? (
        <HeaderMobile
          currentUser={profile}
          onLogout={onLogout}
          isCurrentUserAdmin={isCurrentUserNotTraveller}
          organization={organization}
          breadcrumbs={<Breadcrumbs />}
        />
      ) : (
        <Header
          profile={profile}
          organization={organization}
          isCurrentUserNotTraveller={isCurrentUserNotTraveller}
          breadcrumbs={<Breadcrumbs />}
          onLogout={onLogout}
        />
      )}
    </div>
  );
};

export default HeaderConn;
