import { JsonObject, JsonProperty } from 'json2typescript';
import UserPreferenceModel from './UserPreferenceModel';
import LoyaltyCardModel from './LoyaltyCardModel';
import UserPassportModel from './UserPassportModel';
import UserTravelPolicyModel from './UserTravelPolicyModel';
import OrganizationModel from './OrganizationModel';
import AccountingInvoiceProfileModel from './AccountingInvoiceProfileModel';
import { DateConverter } from './converters';
import PassengerModel from './PassengerModel';

@JsonObject('UserModel')
export default class UserModel {
  @JsonProperty('birthdate_on', DateConverter) public birthdateOn:Date = undefined;
  @JsonProperty('cost_center_required', Boolean) public costCenterRequired:boolean = undefined;
  @JsonProperty('cost_unit_required', Boolean) public costUnitRequired:boolean = undefined;
  @JsonProperty('created_at', DateConverter) public createdAt:Date = undefined;
  @JsonProperty('deleted_at', DateConverter, true) public deletedAt:Date = undefined;
  @JsonProperty('email', String) public email:string = '';
  @JsonProperty('first_name', String) public firstName:string = '';
  @JsonProperty('gender', String) public gender:string = '';
  @JsonProperty('id', Number) public id:number = -1;
  @JsonProperty('last_name', String) public lastName:string = '';
  @JsonProperty('logined_at', DateConverter, true) public loginedAt:Date = undefined;
  @JsonProperty('loyalty_cards', [LoyaltyCardModel]) public loyaltyCards:LoyaltyCardModel[] = [];
  @JsonProperty('middle_name', String) public middleName:string = '';
  @JsonProperty('mobile_phone', String) public mobilePhone:string = '';
  @JsonProperty('office_phone', String) public officePhone:string = '';
  @JsonProperty('organization_id', Number) public organizationId:number = -1;
  @JsonProperty('passports', [UserPassportModel]) public passports:UserPassportModel[] = [];
  @JsonProperty('personnel_number', String, true) public personnelNumber:string = '';
  @JsonProperty('preference', UserPreferenceModel) public preference:UserPreferenceModel = undefined;
  @JsonProperty('private_email', String) public privateEmail:string = '';
  @JsonProperty('receipt_upload_disabled', Boolean) public receiptUploadDisabled:boolean = undefined;
  @JsonProperty('role', String) public role:string = '';
  @JsonProperty('signup_complete', Boolean) public signupComplete:boolean = false;
  @JsonProperty('title', String) public title:string = '';
  @JsonProperty('travel_policy', UserTravelPolicyModel) public travelPolicy:UserTravelPolicyModel = undefined;
  @JsonProperty('updated_at', DateConverter) public updatedAt:Date = undefined;
  @JsonProperty('require_justification_for_policy_violation', Boolean)
    public requireJustificationForPolicyViolation:boolean = undefined;
  @JsonProperty('accounting_invoice_profiles', [AccountingInvoiceProfileModel], true)
    public accountingInvoiceProfiles:AccountingInvoiceProfileModel[] = [];
  @JsonProperty('purpose_of_trip_required', Boolean) public purposeOfTripRequired:boolean = undefined;
  @JsonProperty('reference_code_required', Boolean) public referenceCodeRequired:boolean = undefined;
  @JsonProperty('book_for_my_reports_permission', Boolean) public bookForMyReportsPermission:boolean = false;
  @JsonProperty('zendesk_token', String, true) public zendeskToken: string = undefined;
  @JsonProperty('organization', OrganizationModel) public organization:OrganizationModel = undefined;

  get asPassenger(): PassengerModel {
    const firstPassenger: PassengerModel = new PassengerModel();
    firstPassenger.firstName = this.firstName;
    firstPassenger.lastName = this.lastName;
    firstPassenger.userId = this.id;
    firstPassenger.role = this.role;
    return firstPassenger;
  }
}
