import * as React from 'react';
import { useDispatch } from 'react-redux';

// Utils
import { cond, head } from '@src/shared/src/util/general';
import { useTranslation } from 'react-i18next';
import { baseErrorCond } from '@toolkit/util/app';
// Constants
import { ROLES, SEARCH_TYPE } from '@src/shared/src/const/app';
// Actions
import { searchActions } from '@src/shared/src/actions';
// Models
import { PassengerModel } from '@src/shared/src/models';
// Interfaces
// Components
import { Trans } from 'react-i18next';
import { Label, MenuListDevider, PassengersSelect } from '@toolkit/ui';

type Props = {
  passengers: PassengerModel[];
  searchType: SEARCH_TYPE;
};

const SearchBarPassengersSelector: React.FC<Props> = (props: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [passengersSuggestions, setPassengersSuggestions] = React.useState<PassengerModel[]>([]);

  const onInputChanged = (searchTerm: string) => {
    if (searchTerm && searchTerm.length >= 3) {
      dispatch(
        searchActions.fetchPassengersSuggestionsAsync.request({
          onSuccess: (passengers: PassengerModel[]) => setPassengersSuggestions(passengers),
          onError: cond(baseErrorCond),
          searchTerm,
        }),
      );
    }
  };

  return (
    <div className="tcp-search-bar-passengers-selector">
      <Label>
        {props.searchType === SEARCH_TYPE.RENTAL
          ? t('searchBarPassengersSelector.title.rental')
          : t('search.bar.passengers.selector.title.travellers')}
      </Label>
      <PassengersSelect
        placeholder={t('search.bar.passengers.selector.placeholder.searchForUsers')}
        onInputChange={onInputChanged}
        onChange={(options: PassengerModel[]) =>
          dispatch(searchActions.setSearchPassengers(options))
        }
        selectedValues={props.passengers}
        options={passengersSuggestions}
      />
      {props.passengers.length > 1 && (
        <Label>
          <Trans i18nKey="searchBarPassengersSelector.firstPassenger.main.warning">
            <a target="_blank" href={t('searchBarPassengersSelector.firstPassenger.main.link')}>
              link
            </a>
          </Trans>
        </Label>
      )}
      {props.searchType === SEARCH_TYPE.RENTAL && (
        <>
          <MenuListDevider />
          <b className="pb-1">{t('searchBarPassengersSelector.driverCountMessage.title')}</b>
          <p>{t('searchBarPassengersSelector.driverCountMessage.text')}</p>
        </>
      )}
      {head(props.passengers)?.role === ROLES.GUEST && (
        <Label>{t('searchBarPassengersSelector.firstPassenger.guest.warning')}</Label>
      )}
    </div>
  );
};

export default SearchBarPassengersSelector;
