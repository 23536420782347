import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Utils
import { useTranslation } from 'react-i18next';
import { searchHooks } from '@src/services';
import {
  isMultiCitySearch,
  isHotelOnlySearch,
  isRentalOnlySearch,
  isRoundtripSearch,
} from '@src/shared/src/util/search';
// Constants
// Actions
import { searchActions } from '@src/shared/src/actions';
// Models
import { IRootState } from '@src/store';
import { models } from '@src/shared/src';
// Interfaces
// Components
import { Checkbox } from '@toolkit/ui';
import { SearchBarRouteInput } from '@pod/search/components';
// Styles
import '@pod/search/styles/SearchBarOptions.scss';
import { Collapse } from '@toolkit/ui/Collapse';

type Props = {
  disabled?: boolean;
};

export const SearchBarRoute: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const search = useSelector((state: IRootState) => state.search.currentSearch);
  const isArrivalMissing = searchHooks.useIsArrMissing();
  const isDepartureMissing = searchHooks.useIsDepMisisng();

  const setSearchArrival = (name: string, type: string, coord: models.CoordinatesModel) => {
    dispatch(searchActions.setSearchArr(name));
    dispatch(searchActions.setSearchArrCoor(coord.lat, coord.lng));
    dispatch(searchActions.setSearchArrLocType(type));
  };

  const setSearchDeparture = (name: string, type: string, coord: models.CoordinatesModel) => {
    dispatch(searchActions.setSearchDep(name));
    dispatch(searchActions.setSearchDepCoor(coord.lat, coord.lng));
    dispatch(searchActions.setSearchDepLocType(type));
  };

  const setSectionLocation = (
    sectionIdx: number,
    direction: 'dep' | 'arr',
    name: string,
    type: string,
    coord: models.CoordinatesModel,
  ) => {
    dispatch(
      searchActions.updateSearchSection(sectionIdx, {
        [`${direction}Name`]: name,
        [`${direction}LocType`]: type,
        [`${direction}Lat`]: coord.lat,
        [`${direction}Lng`]: coord.lng,
      }),
    );

    if (isRoundtripSearch(search)) {
      const oppositeDirection = direction === 'dep' ? 'arr' : 'dep';
      const oppositeIndex = sectionIdx === 0 ? 1 : 0;
      dispatch(
        searchActions.updateSearchSection(oppositeIndex, {
          [`${oppositeDirection}Name`]: name,
          [`${oppositeDirection}LocType`]: type,
          [`${oppositeDirection}Lat`]: coord.lat,
          [`${oppositeDirection}Lng`]: coord.lng,
        }),
      );
    }
  };

  const renderSearchBarRouteInput = () => {
    if (isHotelOnlySearch(search)) {
      return (
        <div className="tcp-search-bar-route tcp-search-bar-route-active">
          <SearchBarRouteInput
            name="inputTextTo"
            value={search.arrName}
            setSearchRoute={setSearchArrival}
            displayErrorMsg={isArrivalMissing}
            errorMessage={t('searchBarRoute.form.input.hotelArrival.errorMessage')}
            placeholder={t('search.bar.form.input.placeholder.location')}
          />
        </div>
      );
    }

    if (isRentalOnlySearch(search)) {
      return (
        <div className="tcp-search-bar-route tcp-search-bar-route-active lp-rental-only-search">
          <SearchBarRouteInput
            name="inputTextFrom"
            value={search.depName}
            setSearchRoute={setSearchDeparture}
            placeholder={t('search.bar.form.input.placeholder.from')}
            displayErrorMsg={isDepartureMissing}
            errorMessage={t('searchBarConn.notify.text.departureMissing')}
          />
          {!search.rentalReturnAtPickup && (
            <SearchBarRouteInput
              name="inputTextTo"
              value={search.arrName}
              setSearchRoute={setSearchArrival}
              placeholder={t('search.bar.form.input.placeholder.to')}
              displayErrorMsg={isArrivalMissing}
              errorMessage={t('searchBarConn.notify.text.arrivalMissing')}
            />
          )}
          <div className="checkbox-ret">
            <Checkbox
              isChecked={search.rentalReturnAtPickup}
              label={t('rentalOnlySearch.checkbox.label.returnAndPickup')}
              id="chk-ret"
              onChange={(val) => dispatch(searchActions.setSearchRentalReturnAtPickup(val))}
            />
          </div>
        </div>
      );
    }

    return search.sections.map((section, index) => (
      <div className={`search-bar-route`} key={`search-bar-route-section-${index}`}>
        <Collapse isExpanded={index === 0 || isMultiCitySearch(search)} hasAutoHeight={true}>
          <SearchBarRouteInput
            name="inputTextFrom"
            value={section.depName}
            setSearchRoute={(...args) => setSectionLocation(index, 'dep', ...args)}
            placeholder={t('search.bar.form.input.placeholder.from')}
            displayErrorMsg={section.depLat === -1 || section.depLng === -1}
            errorMessage={t('searchBarConn.notify.text.locationMissing')}
            disabled={props.disabled}
          />
          <SearchBarRouteInput
            name="inputTextTo"
            value={section.arrName}
            setSearchRoute={(...args) => setSectionLocation(index, 'arr', ...args)}
            placeholder={t('search.bar.form.input.placeholder.to')}
            displayErrorMsg={section.arrLat === -1 || section.arrLng === -1}
            errorMessage={t('searchBarConn.notify.text.locationMissing')}
            disabled={props.disabled}
          />
        </Collapse>
      </div>
    ));
  };

  return <div className="tcp-search-bar-route">{renderSearchBarRouteInput()}</div>;
};
export default SearchBarRoute;
