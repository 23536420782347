// Utils
import { store } from '@src/store/store.config';
import { cond } from '@src/shared/src/util/general';
import { baseErrorCond, navigate } from '@toolkit/util/app';
// Constants
import { DIRECTION } from '@src/shared/src/const/app';
import { ROUTES } from '@toolkit/const/app';
// Actions
import {
  tripsActions,
  basketActions,
} from '@src/shared/src/actions';
import { uiActions } from '@pod/ui';
import { shouldSearchForHotels, isOnewaySearch } from '@src/shared/src/util/search';
// Models
// Interfaces
// Subscriptions
// Queries

const { dispatch } = store;

export const fetchTripDetails = (tripId: number, direction: DIRECTION) => {
  dispatch(tripsActions.setExpanded(tripId, false, direction));
  dispatch(tripsActions.fetchTripDetailsAsync.request({
    onError: cond(baseErrorCond),
    tripId,
  }));
};

export const selectTrip = (tripId: number, tariffIds: number[], direction: DIRECTION) => {
  dispatch(uiActions.setUiIsSelectingTrip(true));

  const search = store.getState().search.currentSearch;

  dispatch(basketActions.addTripTariffsToBasketAsync.request({
    tariffIds,
    direction,
    onSuccess: () => {
      store.dispatch(uiActions.setUiIsSelectingTrip(false));
      store.dispatch(
        direction === DIRECTION.INBOUND
          ? tripsActions.setSelectedInboundId(tripId) : tripsActions.setSelectedOutboundId(tripId)
      );

      let nextRoute = ''

      if (direction === DIRECTION.OUTWARD) {
        if (isOnewaySearch(search)) nextRoute = `${ROUTES.BOOKING.RESERVE}${search.id}`;
        else nextRoute = `${ROUTES.TRIPS.INBOUND}${search.id}`;
      } else {
        if (shouldSearchForHotels(search)) nextRoute = `${ROUTES.HOTELS}${search.id}`;
        else nextRoute = `${ROUTES.BOOKING.RESERVE}${search.id}`;
      }
      
      store.dispatch(uiActions.setUiMobileNoScrolling(false));
      navigate(nextRoute);
    },
    onError: (err) => {
      store.dispatch(uiActions.setUiIsSelectingTrip(false));
      return cond(baseErrorCond)(err);
    },
  }));
};
