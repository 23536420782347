import { SVGProps } from 'react';
import * as React from 'react';

export const HotelWarningIcon = (props: SVGProps<SVGSVGElement>): JSX.Element => (
  <svg
    {...props}
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <mask
      id="mask0_2311_7730"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="4"
      y="4"
      width="72"
      height="72">
      <rect x="4" y="4" width="72" height="72" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_2311_7730)">
      <path
        d="M40.168 58C40.61 58 40.9733 57.8527 41.258 57.558C41.5427 57.2627 41.685 56.8973 41.685 56.462V37.538C41.685 37.1027 41.5353 36.7373 41.236 36.442C40.9373 36.1473 40.567 36 40.125 36C39.683 36 39.3197 36.1473 39.035 36.442C38.7503 36.7373 38.608 37.1027 38.608 37.538V56.462C38.608 56.8973 38.7573 57.2627 39.056 57.558C39.3553 57.8527 39.726 58 40.168 58ZM39.991 30.6C40.597 30.6 41.1077 30.3993 41.523 29.998C41.9383 29.5967 42.146 29.0997 42.146 28.507C42.146 27.857 41.9407 27.328 41.53 26.92C41.1187 26.512 40.6093 26.308 40.002 26.308C39.3493 26.308 38.828 26.5157 38.438 26.931C38.0487 27.347 37.854 27.8623 37.854 28.477C37.854 29.0783 38.0587 29.5827 38.468 29.99C38.878 30.3967 39.3857 30.6 39.991 30.6ZM40.04 76C35.028 76 30.346 75.0553 25.994 73.166C21.642 71.2767 17.834 68.696 14.57 65.424C11.3053 62.152 8.72767 58.345 6.837 54.003C4.94567 49.6603 4 44.9933 4 40.002C4 35.01 4.94467 30.328 6.834 25.956C8.72333 21.5847 11.304 17.778 14.576 14.536C17.848 11.294 21.655 8.72767 25.997 6.837C30.3397 4.94567 35.0067 4 39.998 4C44.99 4 49.672 4.94467 54.044 6.834C58.4153 8.72334 62.222 11.2873 65.464 14.526C68.706 17.7647 71.2723 21.5733 73.163 25.952C75.0543 30.33 76 34.9993 76 39.96C76 44.972 75.0553 49.654 73.166 54.006C71.2767 58.358 68.7127 62.16 65.474 65.412C62.2353 68.664 58.4267 71.2417 54.048 73.145C49.67 75.0483 45.0007 76 40.04 76ZM40.012 72.923C49.1627 72.923 56.9357 69.7217 63.331 63.319C69.7257 56.9163 72.923 49.1393 72.923 39.988C72.923 30.8373 69.7337 23.0643 63.355 16.669C56.9763 10.2743 49.1913 7.077 40 7.077C30.8567 7.077 23.0837 10.2663 16.681 16.645C10.2783 23.0237 7.077 30.8087 7.077 40C7.077 49.1433 10.2783 56.9163 16.681 63.319C23.0837 69.7217 30.8607 72.923 40.012 72.923Z"
        fill="#DD3728"
      />
    </g>
  </svg>
);
