import * as React from 'react';
import { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { addDays } from 'date-fns';

// Utils
import { formatDate } from '@src/shared/src/util/date';
import useClickOutside from '@toolkit/util/useClickOutside';
// Constants
import { DATE_FORMAT_TYPES, DIRECTION, MAX_SEARCH_DAYS } from '@src/shared/src/const/app';
// Actions
// Models
// Interfaces
// Components
import { TextField } from '@toolkit/ui';
import { AppContext } from '@toolkit/util/AppContext';
import SearchBarSlideOut from './SearchBarSlideOut';
import SearchBarSlideOutNavbar from './SearchBarSlideOutNavbar';
import SearchBarDateTimeSelector from './SearchBarDateTimeSelector';
import { CalendarIcon } from '@toolkit/ui/icons';
// Styles

type Props = {
  date: Date;
  time?: string;
  placeholder: string;
  direction: DIRECTION;
  minDate?: Date;
  onDateChange?: (val: Date) => void;
  hasError?: boolean;
  disabled?: boolean;
  slideOutClassname?: string;
};

const formatDateTime = (date: Date, time?: string) => {
  if (date) {
    if (time) {
      return `${formatDate(date, DATE_FORMAT_TYPES.SHORT_DATE)} - ${time}`;
    } else {
      return formatDate(date, DATE_FORMAT_TYPES.DAY_SHORT_DATE);
    }
  }

  return '';
};

export const SearchBarDateTimeInput: React.FC<Props> = ({
  date,
  time,
  placeholder,
  direction,
  minDate,
  onDateChange,
  hasError,
  disabled,
  slideOutClassname = '',
}) => {
  const { t } = useTranslation();
  const appContext = useContext(AppContext);
  const [slideOutVisible, setSlideOutVisible] = useState(false);
  const outsideRef = useClickOutside(() => setSlideOutVisible(false));

  const onInputFocused = () => setSlideOutVisible(true);

  return (
    <div ref={outsideRef} className="tcp-search-bar-date-time-item">
      <TextField
        name="date"
        onFocus={onInputFocused}
        value={formatDateTime(date, time)}
        label={placeholder}
        autoComplete={false}
        hasError={hasError}
        icon={appContext.isMediaBPNotMobile && <CalendarIcon />}
        disabled={disabled}
      />

      <SearchBarSlideOut isOpen={slideOutVisible} className={`is--datetime ${slideOutClassname}`}>
        {!appContext.isMediaBPNotMobile ? (
          <SearchBarSlideOutNavbar onClose={() => setSlideOutVisible(false)} />
        ) : null}
        <SearchBarDateTimeSelector
          show={slideOutVisible}
          direction={direction}
          minDate={minDate}
          maxDate={addDays(new Date(), MAX_SEARCH_DAYS)}
          value={date}
          onDateChange={onDateChange}
          onMinDateNullErrorMsg={t(
            'searchBarDateTime.searchBarDateTimeSelector.inbound.onMinDateNullErrorMsg',
          )}
        />
      </SearchBarSlideOut>
    </div>
  );
};
