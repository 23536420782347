import * as React from 'react';

// Utils
import { navigate } from '@toolkit/util/app';
import { isNil } from '@src/shared/src/util/general';
import { t } from '@toolkit/util/i18n';
// Constants
import { ENVIRONMENT, STATUS } from '@src/shared/src/const/app';
import { ROUTES } from '@toolkit/const/app';
// Actions, Models & Interfaces
import { HotelModel } from '@src/shared/src/models';
import { selectors } from '@src/shared/src';
import { appSettingsSelectors } from '@src/shared/src/selectors';
import { IRootState } from '@src/store';
// Components
import {
  CheckoutSideBarHotelInfo,
  CheckoutSideBarTripInfo,
  CheckoutSidebarConfirmBody,
  CheckoutTimer,
} from '@pod/checkout/components';
import { SearchSideBarInfo } from '@pod/search/components';
import { useSelector } from 'react-redux';
import { isMultiCitySearch } from '@src/shared/src/util/search';
// Styles

type Props = {
  bookingId: string;
  env: ENVIRONMENT;
  isBasketEditable: boolean;
  onTimerExpired: () => void;
  exceedsAllowance?: boolean;
};
const CheckoutSideBarConn: React.FC<Props> = ({
  bookingId,
  env,
  isBasketEditable,
  onTimerExpired,
  exceedsAllowance,
}) => {
  const bookingHotels = useSelector((state: IRootState) => state.checkout.hotels);
  const search = useSelector((state: IRootState) => selectors.search.search(state.search));
  const passengers = useSelector((state: IRootState) =>
    selectors.search.searchPassengers(state.search),
  );
  const preparedAt = useSelector((state: IRootState) =>
    selectors.checkout.preparedAt(state.checkout),
  );
  const outwardTrip = useSelector((state: IRootState) =>
    selectors.checkout.outwardTrip(state.checkout),
  );
  const inboundTrip = useSelector((state: IRootState) =>
    selectors.checkout.inboundTrip(state.checkout),
  );
  const basketStatus = useSelector((state: IRootState) => selectors.checkout.basketStatus(state));
  const loyaltyCards = useSelector((state: IRootState) => appSettingsSelectors.loyaltyCards(state));

  return (
    <aside className="sidebar sidebar-trip is-open">
      <div className="sidebar-wrapper">
        {env !== ENVIRONMENT.CONFIRMATION && basketStatus === STATUS.PREPARE_FINISHED && (
          <CheckoutTimer onTimerExpired={onTimerExpired} bookingCreatedAt={preparedAt} />
        )}

        {env === ENVIRONMENT.CHECKOUT || env === ENVIRONMENT.PAYMENT ? (
          <>
            <SearchSideBarInfo
              hideResetButton={exceedsAllowance}
              search={search}
              loyaltyCards={loyaltyCards}
            />

            {!isNil(outwardTrip) && (
              <CheckoutSideBarTripInfo
                key={`trip-${outwardTrip.id}`}
                title={
                  isMultiCitySearch(search)
                    ? `${t('TripSideBarInfo.route')} 1`
                    : t('CheckoutSideBarTripInfo.title.outboundTrip')
                }
                trip={outwardTrip}
                onEdit={
                  isBasketEditable ? () => navigate(`${ROUTES.TRIPS.OUTWARD}${search.id}`) : null
                }
              />
            )}

            {!isNil(inboundTrip) && (
              <CheckoutSideBarTripInfo
                key={`trip-${inboundTrip.id}`}
                title={
                  isMultiCitySearch(search)
                    ? `${t('TripSideBarInfo.route')} 2`
                    : t('CheckoutSideBarTripInfo.title.inboundTrip')
                }
                trip={inboundTrip}
                onEdit={
                  isBasketEditable ? () => navigate(`${ROUTES.TRIPS.INBOUND}${search.id}`) : null
                }
              />
            )}

            {(bookingHotels ?? []).map((hotel: HotelModel, idx: number) => (
              <CheckoutSideBarHotelInfo
                key={`hotel-${idx}`}
                title={t('CheckoutSideBarTripInfo.title.hotel')}
                hotelName={hotel.name}
                checkinDate={search.depAt}
                checkoutDate={search.arrAt}
                travellersCount={passengers.length}
                roomCount={search.hotelRoomCount}
                onEdit={isBasketEditable ? () => navigate(`${ROUTES.HOTELS}${search.id}`) : null}
              />
            ))}
          </>
        ) : (
          <CheckoutSidebarConfirmBody basketStatus={basketStatus as STATUS} bookingId={bookingId} />
        )}
      </div>
    </aside>
  );
};

export default CheckoutSideBarConn;
