import * as React from 'react';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';

// Utils
import {
  isNil,
  cond
} from '@src/shared/src/util/general';
import { baseErrorCond } from '@toolkit/util/app';
// Constants
import { ROUTES } from '@toolkit/const/app';
import { DIRECTION } from '@src/shared/src/const/app';
// Actions
import {
  checkoutActions,
} from '@src/shared/src/actions';
// Models
import {
  BasketModel,
  OrganizationModel,
  UserModel,
} from '@src/shared/src/models';
// Interfaces
import { IConnectedRedux, IRootState } from '@src/store';
// Components
import { SearchConn } from '@pod/search';
import { HeaderConn } from '@pod/header';
import { SupportRequestsConn } from '@pod/support_requests';
import TripsRouter from './TripsRouter';
import HotelsRouter from './HotelsRouter';
import CheckoutRouter from './CheckoutRouter';
import { LoaderOverlay } from '@toolkit/ui';
import { WSStatusBar } from '@src/pod/header/components';
import { ZendeskDemoChat } from '@toolkit/ui';
// Styles

type Props = IConnectedRedux<IRootState> & {
  profile:UserModel;
  basket:BasketModel;
  basketId:number;
  organization: OrganizationModel;
  searchId:number;
};

class AppRouter extends React.Component<Props> {

  componentDidMount() {
    this.initBasket();
  }

  componentDidUpdate(prevProps:Props) {
    if (isNil(this.props.basket) && prevProps.basketId !== this.props.basketId) {
      this.initBasket();
    }
  }

  private initBasket = () => {
    if (this.props.basketId !== -1) {
      this.props.dispatch(
        checkoutActions.initBasket({
          onError: cond(baseErrorCond),
          basketId: this.props.basketId,
        })
      );
    }
  }

  private renderRoutes = () => {
    if (isNil(this.props.basket) && this.props.searchId !== -1 ) {
      return (<LoaderOverlay showSpinner={true}/>);
    }
    if (isNil(this.props.profile)) {
      return (<LoaderOverlay showSpinner={true}/>);
    }
    if (this.props.basket) {
      return (
        <React.Fragment>
          <WSStatusBar/>
          <Switch>
            <Route exact path={`${ROUTES.TRIPS.OUTWARD}:searchId`}
              render={() => (<TripsRouter direction={DIRECTION.OUTWARD}/>)}/>
            <Route exact path={`${ROUTES.TRIPS.INBOUND}:searchId`}
              render={() => (<TripsRouter direction={DIRECTION.INBOUND}/>)}/>
            <Route path={`${ROUTES.HOTELS}`} component={HotelsRouter}/>
            <Route path={ROUTES.BOOKING.DEFAULT} component={CheckoutRouter}/>
            <Route path={ROUTES.SUPPORT_REQUESTS} component={SupportRequestsConn}/>
            <Route path={ROUTES.SEARCH} component={SearchConn}/>
            <Route exact path={ROUTES.HOME} component={SearchConn}/>
          </Switch>
        </React.Fragment>
      );
    } else {
      return (
        <Switch>
          <Route path={ROUTES.SEARCH} component={SearchConn}/>
          <Route exact path={ROUTES.HOME} component={SearchConn}/>
        </Switch>
      );
    }
  }

  render() {
    return (
      <div className={this.props.organization?.isDemo ? 'is--demo' : ''}>
        {<HeaderConn />}
        {this.renderRoutes()}
        {this.props.organization?.isDemo &&
          <ZendeskDemoChat />
        }
      </div>
    );
  }
}
const mapState = (state:IRootState) => ({
  profile: state.adminUser.profile,
  basketId: state.search.currentSearch.basketId,
  basket: state.checkout.basket,
  organization: state.organization.org,
  searchId: state.search.id,
});
export default connect(mapState)(AppRouter);
