import { isActionOf } from 'typesafe-actions';
import {
  filter,
  switchMap,
  map,
  catchError,
  debounceTime,
  mergeMap,
} from 'rxjs/operators';
import { from } from 'rxjs';

// Utils
import { jsonConvert, isNotNilOrEmpty } from '@src/shared/src/util/general';
import { getGoogleSuggestions, getGoogleSuggestionDetail } from '@toolkit/util/app';
import { apiRequestError } from '@src/shared/src/util/api';
// Constants
// Actions, Models & Interfaces
import { generalActions, searchActions } from '@src/shared/src/actions';
import { SearchNodeModel, CoordinatesModel } from '@src/shared/src/models';
import { Epic } from 'redux-observable';
import { RootAction } from '@src/shared/src/interfaces';
// Components
// Styles

export const fetchLocationSuggestionsEpic: Epic<RootAction, RootAction> = (action$) =>
  action$.pipe(
    filter(isActionOf(searchActions.fetchLocationSuggestionsAsync.request)),
    filter((action) => isNotNilOrEmpty(action.payload.term)),
    debounceTime(200),
    switchMap((action) =>
      from(getGoogleSuggestions(action.payload.term)).pipe(
        map((googleSuggestions: any) => {
          const suggestionsList: SearchNodeModel[] = googleSuggestions.map((val) =>
            jsonConvert.deserialize(val, SearchNodeModel),
          );
          return generalActions.applyExtActionAsync.request({
            callback: action.payload.onSuccess,
            param: suggestionsList,
          });
        }),
        catchError((err) =>
          apiRequestError(action.payload.onError, 'fetchLocationSuggestionsError', err),
        ),
      ),
    ),
  );

export const fetchLocationDetailEpic: Epic<RootAction, RootAction> = (action$) =>
  action$.pipe(
    filter(isActionOf(searchActions.fetchLocationDetailAsync.request)),
    mergeMap((action) =>
      from(getGoogleSuggestionDetail(action.payload.location.reference)).pipe(
        map((plCoor: CoordinatesModel) => {
          return generalActions.applyExtActionAsync.request({
            callback: action.payload.onSuccess,
            param: plCoor,
          });
        }),
        catchError((err) =>
          apiRequestError(action.payload.onError, 'FetchLocationDetailError', err),
        ),
      ),
    ),
  );
