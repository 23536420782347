import * as React from 'react';

import { useTranslation } from 'react-i18next';
import { Link } from '@toolkit/ui';
import { ArrowBackIcon } from '@toolkit/ui/icons';
import '../styles/SearchBarSlideOutNavbar.scss';

type Props = {
  onClose: () => void;
  hideConfirmButton?: boolean;
};

const SearchBarSlideOutNavbar: React.FC<Props> = ({ onClose, hideConfirmButton }) => {
  const { t } = useTranslation();

  return (
    <div className="lp-search-bar-slideout-navbar">
      <button onClick={onClose} className="lp-search-bar-slideout-navbar-close">
        <ArrowBackIcon />
        {t('search.bar.slide.out.back')}
      </button>

      {!hideConfirmButton ? (
        <Link onClick={onClose} className="lp-search-bar-slideout-navbar-confirm">
          {t('search.bar.slide.out.button.confirm')}
        </Link>
      ) : (
        ''
      )}
    </div>
  );
};

export default SearchBarSlideOutNavbar;
