import * as React from 'react';

// Utils
import { navigate, isCurrentPath } from '@toolkit/util/app';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectors } from '@src/shared/src';
import { IRootState } from '@src/store';
import {
  containsTransportSearch,
  isMultiCitySearch,
  isRentalOnlySearch,
  isRoundtripSearch,
  shouldSearchForHotels,
} from '@src/shared/src/util/search';
import { verifyProperty } from '@src/shared/src/util/general';
// Constants
import { ROUTES } from '@toolkit/const/app';
// Components
import HeaderItem from './BreadcrumbItem';
// Styles
import '../styles/Breadcrumbs.scss';

const Breadcrumbs: React.FC = () => {
  const { t } = useTranslation();
  const search = useSelector((state: IRootState) => selectors.search.search(state.search));
  const basket = useSelector((state: IRootState) => state.checkout.basket);

  const isBasketEditable = verifyProperty(true, basket, ['editable']);

  const isPathPastPrepare = () =>
    isCurrentPath('/booking/prepare') ||
    isCurrentPath('/booking/payment') ||
    isCurrentPath('/booking/confirm');

  const isInboundHeaderActive: boolean =
    !isPathPastPrepare() &&
    (!isBasketEditable ||
      isCurrentPath(ROUTES.TRIPS.INBOUND) ||
      isCurrentPath(ROUTES.HOTELS) ||
      isCurrentPath('/booking/'));

  const isHotelHeaderActive: boolean =
    !isPathPastPrepare() &&
    (!isBasketEditable || isCurrentPath(ROUTES.HOTELS) || isCurrentPath('/booking/'));

  const isCheckoutHeaderActive: boolean = isBasketEditable && isCurrentPath('/booking/');

  const isOutboundHeaderActive = !isPathPastPrepare();
  let HeaderRoutes =
    isCurrentPath(ROUTES.TRIPS.OUTWARD) ||
    isCurrentPath(ROUTES.TRIPS.INBOUND) ||
    isCurrentPath(ROUTES.HOTELS) ||
    isCurrentPath('/booking/');

  if (HeaderRoutes) {
    return (
      <ol className="breadcrumbs">
        {containsTransportSearch(search) && (
          <HeaderItem
            label={
              isMultiCitySearch(search)
                ? `${t('HeaderComponent.link.route')} 1`
                : t('HeaderComponent.link.outbound')
            }
            activeClassName="breadcrumbs-item-active"
            isActive={isOutboundHeaderActive}
            onClick={() => navigate(`${ROUTES.TRIPS.OUTWARD}${search.id}`)}
          />
        )}

        {(isMultiCitySearch(search) || isRoundtripSearch(search)) && (
          <HeaderItem
            label={
              isMultiCitySearch(search)
                ? `${t('HeaderComponent.link.route')} 2`
                : t('HeaderComponent.link.inbound')
            }
            activeClassName="breadcrumbs-item-active"
            isActive={isInboundHeaderActive}
            onClick={() => navigate(`${ROUTES.TRIPS.INBOUND}${search.id}`)}
          />
        )}

        {shouldSearchForHotels(search) && (
          <HeaderItem
            label={t('HeaderComponent.link.hotel')}
            activeClassName="breadcrumbs-item-active"
            isActive={isHotelHeaderActive}
            onClick={() => navigate(`${ROUTES.HOTELS}${search.id}`)}
          />
        )}

        {isRentalOnlySearch(search) && (
          <HeaderItem
            label={t('HeaderComponent.link.rental')}
            activeClassName="breadcrumbs-item-active"
            isActive={false}
          />
        )}

        <HeaderItem
          label={t('HeaderComponent.link.checkout')}
          activeClassName="breadcrumbs-item-active"
          isActive={isCheckoutHeaderActive}
          onClick={() => navigate(`${ROUTES.BOOKING.RESERVE}${search.id}`)}
        />
      </ol>
    );
  }
};

export default Breadcrumbs;
