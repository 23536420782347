import { getType } from 'typesafe-actions';
import { uiActions } from '.';
import { generateActionKeys } from '@toolkit/util/state-utils/action.util';

export interface IUIState {
  uiMobileFilterOverlay: boolean;
  uiTripChangingTariffsloader: boolean;
  uiMobileNoScrolling: boolean;
  isSelectingTrip: boolean;
  isSelectingHotel: boolean;
  isConfirmingBooking: boolean;
}

const initialState: IUIState = {
  uiMobileFilterOverlay: false,
  uiTripChangingTariffsloader: false,
  uiMobileNoScrolling: false,
  isSelectingTrip: false,
  isSelectingHotel: false,
  isConfirmingBooking: false,
};

export const UIActions = generateActionKeys<IUIState>(initialState, 'UI');

export default (state: IUIState = initialState, action: any) => {
  switch (action.type) {
    case getType(uiActions.setUiMobileFilterOverlay):
      return { ...state, uiMobileFilterOverlay: action.payload };
    case getType(uiActions.setUiTripChangingTariffsloader):
      return { ...state, uiTripChangingTariffsloader: action.payload };
    case getType(uiActions.setUiMobileNoScrolling):
      return { ...state, uiMobileNoScrolling: action.payload };
    case getType(uiActions.setUiIsSelectingTrip):
      return { ...state, isSelectingTrip: action.payload };
    case getType(uiActions.setUiIsSelectingHotel):
      return { ...state, isSelectingHotel: action.payload };
    case UIActions.isConfirmingBooking:
      return { ...state, isConfirmingBooking: action.payload };
    default:
      return state;
  }
};
