import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useQueryParams } from './useQueryParams';

// Utils
import { getOppositeDirection } from '@src/shared/src/util/app';
// Constants
import { DIRECTION, SEARCH_TYPE } from '@src/shared/src/const/app';
// Models
import { IRootState } from '@src/store';
import { RebookingModel, SearchModel, TravelBookingItemModel } from '@src/shared/src/models';
// Actions
import {
  invertSearchLocations,
  setSearchArrAt,
  setSearchDepAt,
  setSearchRebooking,
} from '@src/shared/src/actions/searchActions';
import { searchActions } from '@src/shared/src/actions';

const getFareIdDirection = (fareId: number, bookingItem: TravelBookingItemModel) => {
  if (bookingItem.fareIds.includes(fareId)) {
    if (bookingItem.fareId === fareId) {
      return bookingItem.direction;
    } else {
      return getOppositeDirection(bookingItem.direction);
    }
  }
};

export const useRebookingSearch = (search: SearchModel) => {
  const dispatch = useDispatch();
  const query = useQueryParams();

  const basketItems = useSelector((state: IRootState) => state.checkout.basketItems);

  const bookingItemId = Number(query.get('original_booking_item_id'));
  const originalFareId = Number(query.get('original_fare_id'));
  const originalFareType = query.get('original_fare_type');
  const basketItem = basketItems?.find((item) => item.travelBookingItem?.id === bookingItemId);
  useEffect(() => {
    if (search.id !== -1 && basketItem) {
      const rebooking: RebookingModel = new RebookingModel({
        originalTravelBookingId: basketItem.travelBookingItem.bookingId,
        originalTravelBookingItemId: bookingItemId,
        originalFareId: originalFareId,
        originalFareType: originalFareType,
        originalFareDirection: getFareIdDirection(originalFareId, basketItem.travelBookingItem),
      });

      dispatch(setSearchRebooking(rebooking));

      if (basketItem.travelBookingItem.isRoundtrip) {
        dispatch(searchActions.setSearchType(SEARCH_TYPE.ALL));
      } else {
        dispatch(searchActions.setSearchType(SEARCH_TYPE.OUTBOUND));
        dispatch(setSearchArrAt(null));

        if (basketItem.travelBookingItem.direction === DIRECTION.INBOUND) {
          dispatch(invertSearchLocations());
          dispatch(setSearchDepAt(search.arrAt));
        }
      }
    } else {
      dispatch(setSearchRebooking(null));
    }
  }, [search.id, basketItem?.id]);
};
