import * as React from 'react';
import { useSelector } from 'react-redux';

// Utils
import { t } from '@toolkit/util/i18n';
import { filter, formatCents, prop, propEq, sortBy } from '@src/shared/src/util/general';
import { ACCOMMODATION_TYPES, ENVIRONMENT } from '@src/shared/src/const/app';
import { isEnvSearch } from '@src/util/app';
import { FARE_PAYMENT_TYPE } from '@src/util/const';
// Actions, Models & Interfaces
import { HotelFareModel } from '@src/shared/src/models';
// Components
import HotelFacilities from './HotelFacilities';
import HotelRoomDetails from './HotelRoomDetails';
import { HotelFareRefundableUntil } from './HotelFareRefundableUntil';
import { Button } from '@toolkit/ui';
// Styles
import '../styles/HotelFare.scss';
// Store
import { prepayHotelRatesFilterEnabled } from '@src/store/selectors';

type Props = {
  fares: HotelFareModel[];
  facilities: string[];
  hotelRoomCount?: number;
  env: ENVIRONMENT;
  isSelectingHotel?: boolean;
  isBasketEditable?: boolean;
  accommodationType: ACCOMMODATION_TYPES;
  onSelectHotel?: (hotelFareIds: string[]) => void;
};

export const HotelFare: React.FC<Props> = props => {
  const isPrepayHotelRatesFilterEnabled = useSelector(prepayHotelRatesFilterEnabled);

  const { isBasketEditable, env } = props;
  const fares = isEnvSearch(env) ? sortBy(prop('price'))(props.fares) : filter(propEq('selected', true))(props.fares);

  const renderSelectionBtn = (fareId: string, farePaymentType: string) => {
    if (isPrepayHotelRatesFilterEnabled && farePaymentType === FARE_PAYMENT_TYPE.PREPAY) {
      return <Button
        className="button"
        disabled={true}>
        <i className="icon-info_outline" data-rh={t('hotelFare.tooltip.prepayHotelRatesDisabled')} />&nbsp;{t('global.deactivated')}
      </Button>
    }
    else if (isEnvSearch(env) && isBasketEditable) {
      return <Button
        className="button"
        onClick={() => props.onSelectHotel([fareId])}
        isLoading={props.isSelectingHotel}>
        {t('global.select')}
      </Button>
    }
    else {
      return props.hotelRoomCount
    }
  }

  return (
    <div>
      <table className="tcp-hotel-fare">
        <thead>
          <tr>
            <th>{t('hotelFare.thead.facilitiesInTheRoom')}</th>
            <th>{t('hotelFare.thead.roomType')}</th>
            <th>{t('hotelFare.thead.totalPrice')}</th>
            <th>{t('hotelFare.thead.breakfast')}</th>
            <th>{t('hotelFare.thead.cancellable')}</th>
            <th>{isEnvSearch(env) ? '' : t('hotelFare.thead.rooms')}</th>
          </tr>
        </thead>
        <tbody>
          {fares.map((fare: HotelFareModel, index: number) => (
            <tr key={fare.id}>
              {index === 0 && (
                <td rowSpan={fares.length}>
                  <HotelFacilities mode={'ROOM'} facilities={props.facilities} />
                </td>
              )}
              <td>
                {props.hotelRoomCount ? `${props.hotelRoomCount} x ` : ''}
                {fare.roomType}
                <HotelRoomDetails roomDetails={fare.roomDetails} />
              </td>
              <td>
                <strong data-rh={fare.provider ? `${t('hotelFare.cell.price.provider.via')} ${fare.provider}` : undefined}>
                  {formatCents(fare.price)}
                </strong>&nbsp;
                {fare.paymentType === FARE_PAYMENT_TYPE.PREPAY &&
                  (<i className="icon-info_outline" data-rh={t('hotelFare.cell.price.tooltip.prepay')} />)}
                {fare.negotiatedRate && (<i data-rh={t('hotel.tooltip.fare.negotiatedRates')} className="icon-monetization_on" />)}
              </td>
              <td>
                <i className={fare.breakfast ? 'icon-check' : 'icon-disabled'}
                  data-rh={
                    fare.breakfastSurcharge && !fare.breakfast
                      ? t('hotelFare.tooltip.breakfastSurcharge', {
                        breakfastSurcharge: formatCents(fare.breakfastSurcharge),
                      })
                      : undefined
                  }
                />
              </td>
              <td>
                <HotelFareRefundableUntil
                  refundableUntil={fare.refundableUntil}
                  conditions={fare.conditions}
                  deepLink={fare.deepLink}
                  accommodationType={props.accommodationType} />
              </td>
              <td>
                {renderSelectionBtn(fare.id, fare.paymentType)}
                {!isBasketEditable && fare.selected && <i className="icon-check" />}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
