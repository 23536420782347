import * as React from 'react';

// Utils
import { formatDate, formatDateRange } from '@src/shared/src/util/date';
// Constants
import { DATE_FORMAT_TYPES } from '@src/shared/src/const/app';
// Actions
// Components
import { SidebarRoute } from '@toolkit/ui';
import { CalendarIcon, PinIcon } from '@toolkit/ui/icons';
// Styles

type Props = {
  title: string;
  from: string;
  to: string;
  depAt: Date;
  arrAt?: Date;
};

const SearchSideBarRouteInfo: React.FC<Props> = ({ title, from, to, depAt, arrAt }) => {
  return (
    <div className="sidebar-route-section">
      <div className="sidebar-title">{title}</div>
      {from && <SidebarRoute icon={<PinIcon />}>{from}</SidebarRoute>}
      {to && <SidebarRoute icon={<PinIcon />}>{to}</SidebarRoute>}
      {depAt && (
        <SidebarRoute icon={<CalendarIcon />}>
          {arrAt
            ? formatDateRange(depAt, arrAt, '-', DATE_FORMAT_TYPES.DAY_SHORT_DATE)
            : formatDate(depAt, DATE_FORMAT_TYPES.DAY_SHORT_DATE)}
        </SidebarRoute>
      )}
    </div>
  );
};

export default SearchSideBarRouteInfo;
