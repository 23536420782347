import { JsonObject, JsonProperty } from 'json2typescript';
import { DateConverter } from './converters';

@JsonObject('SearchSectionModel')
export default class SearchSectionModel {
  @JsonProperty('dep_at', DateConverter) depAt: Date = undefined;
  @JsonProperty('dep_lat', Number) depLat: number = -1;
  @JsonProperty('dep_lng', Number) depLng: number = -1;
  @JsonProperty('dep_geocoded_name', String) depName: string = '';
  @JsonProperty('dep_loc_type', String) depLocType: string = undefined;
  @JsonProperty('arr_lat', Number) arrLat: number = -1;
  @JsonProperty('arr_lng', Number) arrLng: number = -1;
  @JsonProperty('arr_geocoded_name', String) arrName: string = '';
  @JsonProperty('arr_loc_type', String) arrLocType: string = undefined;

  constructor(init?: Partial<SearchSectionModel>) {
    Object.assign(this, init);
  }
}
