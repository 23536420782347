import * as React from 'react';

// Utils
import { navigate } from '@toolkit/util/app';
import { formatDate, formatDateRange } from '@src/shared/src/util/date';
import { AppContext } from '@toolkit/util/AppContext';
import { useTranslation } from 'react-i18next';
// Constants
import { DATE_FORMAT_TYPES, SEARCH_TYPE } from '@src/shared/src/const/app';
import { DIRECTION } from '@src/shared/src/const/app';
// Models
import { SearchModel } from '@src/shared/src/models';
// Components
import { Navbar } from '@toolkit/ui';

type Props = {
  search: SearchModel;
  direction: DIRECTION;
};

const TripsMobileNavbar: React.FC<Props> = ({ search, direction }) => {
  const { t } = useTranslation();
  const appContext = React.useContext(AppContext);

  const getHeaderMarkup = () => {
    if (search.searchType === SEARCH_TYPE.MULTICITY)
      return t('tripsConn.navbar.route', {
        index: direction === DIRECTION.OUTWARD ? 1 : 2,
      });

    return t(`tripsConn.navbar.${direction}`);
  };

  const getDateMarkup = () => {
    switch (search.searchType) {
      case SEARCH_TYPE.OUTBOUND:
      case SEARCH_TYPE.MULTICITY:
        return formatDate(
          search.sections[direction === DIRECTION.OUTWARD ? 0 : 1].depAt,
          DATE_FORMAT_TYPES.DAY_SHORT_DATE,
        );
      case SEARCH_TYPE.ALL:
        return formatDateRange(
          search.sections[0].depAt,
          search.sections[1].depAt,
          '-',
          DATE_FORMAT_TYPES.DAY_SHORT_DATE,
        );
      default:
        throw new Error('Invalid search type');
    }
  };

  if (!appContext.isMediaBPNotMobile) {
    return (
      <Navbar
        showBackButton={true}
        iconRight="icon-edit"
        actionRight={() => navigate('/')}
        theme={direction === DIRECTION.INBOUND ? direction : 'outbound'}>
        <div>
          {getHeaderMarkup()}
          <div className="lp-navbar-subtitle">{getDateMarkup()}</div>
        </div>
      </Navbar>
    );
  }
};

export default TripsMobileNavbar;
