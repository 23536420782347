import * as React from 'react';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Utils
import { cond } from '@src/shared/src/util/general';
import { baseErrorCond } from '@toolkit/util/app';
// Constants
// Actions
import { hotelActions } from '@src/shared/src/actions';
// Models
// Interfaces
// Components
import { LabelButton } from '@toolkit/ui';
// Styles
import '../styles/HotelTitle.scss';

type Props = {
  hotelId: string;
  isFavorite: boolean;
};

const HotelFavoriteButton: React.FC<Props> = ({ hotelId, isFavorite }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isHotelFavorite, setIsHotelFavorite] = React.useState(isFavorite);

  const toggleFavorite = () => {
    setIsHotelFavorite(!isHotelFavorite);
    dispatch(
      hotelActions.setFavoriteHotelAsync.request({
        onError: (err) => {
          setIsHotelFavorite(isHotelFavorite); // Reset to previous state if error
          cond(baseErrorCond)(err);
        },
        isFavorite: !isHotelFavorite,
        hotelId,
      }),
    );
  };

  return (
    <LabelButton
      icon="icon-favorite"
      className={classNames(isHotelFavorite ? 'tcp-hotel-title-is-favorite' : '', isHotelFavorite)}
      onClick={toggleFavorite}>
      {isHotelFavorite
        ? t('hotelTitle.btn.label.isFavorite')
        : t('hotelTitle.btn.label.markFavorite')}
    </LabelButton>
  );
};

export default HotelFavoriteButton;
