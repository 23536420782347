import * as React from 'react';

// Utils
import { isNilOrEmpty, isNil } from '@src/shared/src/util/general';
import { getUserFullname } from '@src/shared/src/util/users';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
// Constants
// Actions
import { selectors } from '@src/shared/src';
// Models
import { PassengerModel } from '@src/shared/src/models';
// Interfaces
import { IRootState } from '@src/store';
// Components
import { SidebarRoute } from '@toolkit/ui';
import { AccountCirlceIcon } from '@toolkit/ui/icons';
// Styles

const SideBarPassengersInfo: React.FC = () => {
  const { t } = useTranslation();
  const passengers = useSelector((state: IRootState) =>
    selectors.search.searchPassengers(state.search),
  );

  const filteredPassengers = passengers.map((passenger: PassengerModel, index: number) => {
    if (!isNil(passenger)) {
      return (
        <SidebarRoute icon={<AccountCirlceIcon />} key={index}>
          {getUserFullname(passenger)}
          &nbsp;
          {!isNilOrEmpty(passenger.membershipCards) && `(${passenger.membershipCards.join(' | ')})`}
        </SidebarRoute>
      );
    }
    return null;
  });

  return (
    <div className="sidebar-route-section">
      <div className="sidebar-title">{t('tripBookedInfo.label.passengers')}</div>
      {filteredPassengers}
    </div>
  );
};

export default SideBarPassengersInfo;
