import { createAction } from 'typesafe-actions';

export const setWSCallStart = createAction(
  'settings/SET_WS_CALL_START',
  (resolve) => (wsCallKey:string) => resolve(wsCallKey)
);

export const setWSCallEnd = createAction(
  'settings/SET_WS_CALL_END',
  (resolve) => (wsCallKey:string) => resolve(wsCallKey)
);

export const setWSStatus = createAction(
  'settings/SET_WS_STATUS',
  (resolve) => (wsStatus:boolean) => resolve(wsStatus)
);

export const startWSCheck = createAction('settings/START_WS_CHECK');

export const clearWSCalls = createAction('settings/CLEAR_WS_CALLS');