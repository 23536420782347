import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { addDays, isBefore, startOfDay } from 'date-fns';

// Utils
import { useTranslation } from 'react-i18next';
import {
  getSectionMinDate,
  isOnewaySearch,
  isHotelOnlySearch,
  isRentalOnlySearch,
} from '@src/shared/src/util/search';
// Hooks
import { searchHooks } from '@src/services';
// Constants
import { DIRECTION, SEARCH_TYPE } from '@src/shared/src/const/app';
// Actions, Models & Interfaces
import { searchActions } from '@src/shared/src/actions';
import { IRootState } from '@src/store';
// Components
import { SearchBarDateTimeInput } from '@pod/search/components';
// Styles
require('react-day-picker/lib/style.css');
require('@pod/search/styles/SearchBarDateTime.scss');

type Props = {
  disableToggleButton?: boolean;
  disableDepDatePicker?: boolean;
  disableArrDatePicker?: boolean;
};

const SearchBarDateTime: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const search = useSelector((state: IRootState) => state.search.currentSearch);

  const isArrivalDateMissing = searchHooks.useIsArrDateMissing();
  const isDepartureDateMissing = searchHooks.useIsDepDateMissing();

  const handleOnDateChange = (date: Date, isDep: boolean) => {
    if (isDep) {
      dispatch(searchActions.setSearchDepAt(date));
      if (isBefore(search.arrAt, date)) dispatch(searchActions.setSearchArrAt(addDays(date, 3)));
    } else {
      dispatch(searchActions.setSearchArrAt(date));
    }
  };

  const setSectoionDepDate = (date: Date, sectionIndex: number) => {
    dispatch(searchActions.updateSearchSection(sectionIndex, { depAt: date }));
    if (sectionIndex < search.sections.length - 1) {
      const nextSection = search.sections[sectionIndex + 1];
      if (isBefore(nextSection.depAt, date)) {
        dispatch(searchActions.updateSearchSection(sectionIndex + 1, { depAt: addDays(date, 3) }));
      }
    }
  };

  const getDateTimeTitle = (sectionIndex: number) => {
    switch (search.searchType) {
      case SEARCH_TYPE.HOTEL:
        return sectionIndex === 0
          ? t('search.bar.form.date.label.checkin')
          : t('search.bar.form.date.label.checkout');
      case SEARCH_TYPE.RENTAL:
        return sectionIndex === 0
          ? t('searchBarDateTime.form.label.pickupTime')
          : t('searchBarDateTime.form.label.returnTime');
      case SEARCH_TYPE.MULTICITY:
        return `${sectionIndex + 1}. ${t('search.bar.form.date.label.section')}`;

      case SEARCH_TYPE.OUTBOUND:
      case SEARCH_TYPE.ALL:
        return sectionIndex === 0
          ? t('search.bar.form.date.label.outbound')
          : t('search.bar.form.date.label.inbound');
      default:
        throw new Error('Invalid search type');
    }
  };

  if (isHotelOnlySearch(search) || isRentalOnlySearch(search)) {
    return (
      <div className="tcp-search-bar-date-time">
        <SearchBarDateTimeInput
          date={search.depAt}
          time={isRentalOnlySearch(search) ? search.depRentalTime : null}
          direction={DIRECTION.OUTWARD}
          onDateChange={(date) => handleOnDateChange(date, true)}
          minDate={startOfDay(new Date())}
          placeholder={getDateTimeTitle(0)}
          hasError={isDepartureDateMissing}
          disabled={props.disableDepDatePicker}
        />
        <SearchBarDateTimeInput
          date={search.arrAt}
          time={isRentalOnlySearch(search) ? search.arrRentalTime : null}
          direction={DIRECTION.INBOUND}
          onDateChange={(date) => handleOnDateChange(date, false)}
          minDate={search.depAt}
          placeholder={getDateTimeTitle(1)}
          hasError={isArrivalDateMissing}
          slideOutClassname="is--inbound"
          disabled={props.disableArrDatePicker}
        />
      </div>
    );
  }

  return (
    <div className="tcp-search-bar-date-time tcp-search-bar-date-time-wrapper">
      <div className="tcp-search-bar-date-time">
        {search.sections.map((section, index) => (
          <SearchBarDateTimeInput
            key={`date-input-section-${index}`}
            date={section.depAt}
            direction={index % 2 === 0 ? DIRECTION.OUTWARD : DIRECTION.INBOUND}
            onDateChange={(date) => setSectoionDepDate(date, index)}
            minDate={getSectionMinDate(search, index)}
            placeholder={getDateTimeTitle(index)}
            hasError={!section.depAt}
          />
        ))}
        {isOnewaySearch(search) && (
          <SearchBarDateTimeInput
            key="disabled"
            date={null}
            direction={DIRECTION.INBOUND}
            placeholder={getDateTimeTitle(1)}
            disabled
          />
        )}
      </div>
    </div>
  );
};

export default SearchBarDateTime;
