import * as React from 'react';

// Utils
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

// Constants
// Actions
import { searchActions } from '@src/shared/src/actions';
// Models
import { IRootState } from '@src/store';
// Interfaces
// Components
import { Dropdown, Radio } from '@toolkit/ui';
// Styles

type Props = {
  alignRight: boolean;
};

export const SearchBarRoomsSelector: React.FC<Props> = ({ alignRight }) => {
  const { t } = useTranslation();
  const roomTypes = [
    { value: 1, label: t('searchBarRoomsSelector.select.option.singleRoom') },
    { value: 2, label: t('searchBarRoomsSelector.select.option.doubleRoom') },
  ];

  const currentRoomType = useSelector((state: IRootState) => state.search.currentSearch.roomType);

  const dispatch = useDispatch();
  const onSetSearchHotelRoomsType = (val: number) =>
    dispatch(searchActions.setSearchHotelRoomsType(val));

  const getLabel = () => {
    return roomTypes.find((type) => currentRoomType === type.value).label;
  };

  return (
    <Dropdown
      title={t('searchBarRoomsSelector.title.hotelRooms')}
      label={getLabel()}
      rightAlingOnMobile={alignRight}>
      {roomTypes.map((option) => (
        <Radio
          key={String(option.value)}
          id={String(option.value)}
          name={String(option.value)}
          value={String(option.value)}
          label={option.label}
          checked={option.value === currentRoomType}
          onChange={() => onSetSearchHotelRoomsType(Number(option.value))}
        />
      ))}
    </Dropdown>
  );
};
