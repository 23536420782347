import { createAction, createAsyncAction } from 'typesafe-actions';

// Utils
// Constants
// Actions
// Models
import {
  SearchNodeModel,
  SearchModel,
  TravelSearchAggregatorModel,
  PassengerModel,
  EventListingModel,
  RebookingModel,
  SearchSectionModel,
} from '../models';
// Interfaces
import { ActionCallback } from '../interfaces';
import { SEARCH_TYPE } from '../const/app';

export const setId = createAction('search/SET_ID', (resolve) => (val: number) => resolve(val));

export const setSearchId = createAction('search/SET_SEARCH_ID', (resolve) => (val: number) => resolve(val));

export const setSearchDep = createAction('search/SET_DEP', (resolve) => (val: string) => resolve(val));

export const setSearchArr = createAction('search/SET_ARR', (resolve) => (val: string) => resolve(val));

export const setSearchDepAt = createAction('search/SET_DEP_AT', (resolve) => (val: Date) => resolve(val));

export const setSearchArrAt = createAction('search/SET_ARR_AT', (resolve) => (val: Date) => resolve(val));

export const setSearchArrLocType = createAction('search/SET_ARR_LOC_TYPE', (resolve) => (val: string) => resolve(val));

export const setSearchDepLocType = createAction('search/SET_DEP_LOC_TYPE', (resolve) => (val: string) => resolve(val));

export const setSearchType = createAction('search/SET_TYPE', (resolve) => (val: SEARCH_TYPE) => resolve(val));

export const invertSearchLocations = createAction(
  'search/INVERT_SEARCH_LOCATIONS',
  (resolve) => () => resolve(),
);

export const setSearchRebooking = createAction(
  'search/SET_SEARCH_REBOOKING',
  (resolve) => (val: Partial<RebookingModel>) => resolve(val),
);

export const setSearchDepRentalTime = createAction('search/SET_SEARCH_DEP_RENTAL_TIME',
  (resolve) => (val: string) => resolve(val));

export const setSearchArrRentalTime = createAction('search/SET_SEARCH_ARR_RENTAL_TIME',
  (resolve) => (val: string) => resolve(val));

export const setSearchRentalReturnAtPickup = createAction(
  'search/SET_SEARCH_RENTAL_RETURN_AT_PICKUP', (resolve) => (val: boolean) => resolve(val));

export const setSearchDepCoor = createAction(
  'search/SET_DEP_COOR',
  (resolve) => (lat: number, lng: number) => resolve({ lat, lng })
);

export const setSearchArrCoor = createAction(
  'search/SET_ARR_COOR',
  (resolve) => (lat: number, lng: number) => resolve({ lat, lng })
);

export const setSearchHotelRoomsCount = createAction(
  'search/SET_HOTEL_ROOM_COUNT',
  (resolve) => (val: number) => resolve(val)
);

export const setSearchHotelRoomsType = createAction(
  'search/SET_HOTEL_ROOM_TYPE',
  (resolve) => (val: number) => resolve(val)
);

export const setSearchFlightCabinClass = createAction(
  'search/SET_SEARCH_FLIGHT_CABIN_CLASS',
  (resolve) => (val: string) => resolve(val)
);

export const setBasketId = createAction(
  'search/SET_BASKET_ID',
  (resolve) => (val: number) => resolve(val)
);

export const setSearch = createAction(
  'search/SET_SEARCH',
  (resolve) => (val: SearchModel) => resolve(val)
);

export const clearSearch = createAction('search/CLEAR_SEARCH');

export const setSearchExpiration = createAction('search/SET_EXPIRATION', (resolve) => (val: boolean) => resolve(val));

export const addTransportSearchAggregator = createAction(
  'search/ADD_TRANSPORT_SEARCH_AGGREGATOR',
  (resolve) => (val: TravelSearchAggregatorModel) => resolve(val)
);

export const addHotelSearchAggregator = createAction(
  'search/ADD_HOTEL_SEARCH_AGGREGATOR',
  (resolve) => (val: TravelSearchAggregatorModel) => resolve(val)
);

export const clearTransportSearchesAggregators = createAction('search/CLEAR_TRANSPORT_SEARCHES_AGGREGATORS');

export const clearHotelSearchesAggregators = createAction('search/CLEAR_HOTEL_SEARCHES_AGGREGATORS');

export const initSearch = createAction(
  'search/INIT_SEARCH',
  (resolve) => (val: ActionCallback & { searchId: number }) => resolve(val)
);

export const setLastWSUpdated = createAction(
  'search/SET_LAST_WS_UPDATED',
  (resolve) => (date: number) => resolve(date)
);

export const setSearchPassengers = createAction(
  'search/SET_PASSENGERS',
  (resolve) => (passengers: PassengerModel[]) => resolve(passengers)
);

export const setSearchSections = createAction(
  'search/SET_SECTIONS',
  (resolve) => (sections: SearchSectionModel[]) => resolve(sections)
);

export const updateSearchSection = createAction(
  'search/UPDATE_SECTION',
  (resolve) => (index: number, newSectionValues: Partial<SearchSectionModel>) =>
    resolve({ index, newSectionValues }),
);

export const setSearchEventListing = createAction(
  'search/SET_SEARCH_EVENT_LISTING',
  (resolve) => (eventListing: EventListingModel) => resolve(eventListing)
);

export const setSearchEventsStatus = createAction(
  'search/SET_SEARCH_EVENTS_STATUS',
  (resolve) => (eventsStatus: String) => resolve(eventsStatus)
);

// Async actions
export const startSearchAsync = createAsyncAction(
  'search/START_SEARCH_REQUEST',
  'search/START_SEARCH_SUCCESS',
  'search/START_SEARCH_FAILURE'
)<ActionCallback & {
  search: SearchModel,
}, void, void>();

export const fetchLocationDetailAsync = createAsyncAction(
  'search/FETCH_LOCATION_DETAIL_REQUEST',
  'search/FETCH_LOCATION_DETAIL_SUCCESS',
  'search/FETCH_LOCATION_DETAIL_FAILURE'
)<ActionCallback & { location: SearchNodeModel }, void, void>();

export const fetchPassengersSuggestionsAsync = createAsyncAction(
  'search/FETCH_PASSENGERS_SUGGESTIONS_REQUEST',
  'search/FETCH_PASSENGERS_SUGGESTIONS_SUCCESS',
  'search/FETCH_PASSENGERS_SUGGESTIONS_FAILURE'
)<ActionCallback & { searchTerm: string }, void, void>();

export const fetchEventsAsync = createAsyncAction(
  'search/FETCH_EVENTS_REQUEST',
  'search/FETCH_EVENTS_SUCCESS',
  'search/FETCH_EVENTS_FAILURE',
)<ActionCallback & { searchId: number }, void, void>();

export const fetchLocationSuggestionsAsync = createAsyncAction(
  'search/FETCH_LOCATION_SUGGESTIONS_REQUEST',
  'search/FETCH_LOCATION_SUGGESTIONS_SUCCESS',
  'search/FETCH_LOCATION_SUGGESTIONS_FAILURE',
)<ActionCallback & { term: string }, void, void>();
