// Utils
// Constants
import { DIRECTION, FLIGHTS_CABIN_CLASS, LOG_TYPE } from '../const/app';
import SupplierCodes from './SupplierCodes';
import { find, propEq, isNilOrEmpty } from './general';
import { errorLogger } from './errors';
// Actions
// Models
// Interfaces

export const applyExtAction = (callback:Function, param: any) => (callback) ? callback(param) : null;

export const getSupplierNameByCode = (code:string) => {
  const supplier = find(propEq('ident', code))(SupplierCodes);

  if (isNilOrEmpty(supplier)) {
    // Use the code as is
    return code;
  }
  return supplier.name;
};

export const getFlightCabinClassByKey = (key:string) => {
  if (isNilOrEmpty(key)) {
    errorLogger('Flight cabin class cannot be null or empty', LOG_TYPE.ERROR);
    return FLIGHTS_CABIN_CLASS.ECONOMY;
  }

  switch (key[0].toUpperCase()) {
    case 'E':
      return FLIGHTS_CABIN_CLASS.ECONOMY;
    case 'P':
      return FLIGHTS_CABIN_CLASS.PREMIUM_ECONOMY;
    case 'B':
      return FLIGHTS_CABIN_CLASS.BUSINESS;
    case 'F':
      return FLIGHTS_CABIN_CLASS.FIRST;
    default:
      return FLIGHTS_CABIN_CLASS.ECONOMY;
  }
};

export const getOppositeDirection = (direction: DIRECTION) =>
  direction === DIRECTION.OUTWARD ? DIRECTION.INBOUND : DIRECTION.OUTWARD;
