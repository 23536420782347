import * as React from 'react';
import { ModalProps, default as ReactResponsiveModal } from 'react-responsive-modal';

// Utils
import { isNil } from '@src/shared/src/util/general';
// Constants
// Actions
// Models
// Interfaces
// Components
import { CloseIcon } from './icons';
// Styles
import 'react-responsive-modal/styles.css';
import './styles/Modal.scss';

type Props = React.PropsWithChildren<
  Pick<ModalProps, 'showCloseIcon' | 'closeOnOverlayClick' | 'closeOnEsc' | 'classNames'> & {
    isOpen: boolean;
    onClose?: () => void;
    title?: string;
  }
>;

const Modal: React.FC<Props> = ({
  showCloseIcon = true,
  closeOnOverlayClick = true,
  closeOnEsc = true,
  classNames = { overlay: 'lp-modal-overlay', modal: 'lp-modal' },
  isOpen,
  onClose,
  title,
  children,
}) => {
  return (
    <ReactResponsiveModal
      open={isOpen}
      onClose={onClose}
      classNames={classNames}
      showCloseIcon={showCloseIcon}
      closeOnOverlayClick={closeOnOverlayClick}
      closeOnEsc={closeOnEsc}
      closeIcon={<CloseIcon />}>
      {!isNil(title) && (
        <div className="lp-modal-header">
          <h2 className="lp-modal-title">{title}</h2>
        </div>
      )}

      <div className="lp-modal-body">{children}</div>
    </ReactResponsiveModal>
  );
};
export default Modal;
