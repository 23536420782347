import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Utils
import { useTranslation } from 'react-i18next';
import { settingsToSelectOptions } from '@src/shared/src/util/settings';
// Constants
import { IRootState } from '@src/store';
// Actions
import { setSearchFlightCabinClass } from '@src/shared/src/actions/searchActions';
// Models
// Interfaces
import { OptionModel } from '@src/shared/src/models';
// Components
import { Radio, Dropdown } from '@toolkit/ui';
// Styles

export const SearchBarCabinClassSelector: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const availableFlightCabinClasses = useSelector(
    (state: IRootState) => state.appSettings.config.flightCabinClasses,
  );
  const searchFlightCabinClass = useSelector(
    (state: IRootState) => state.search.currentSearch.flightCabinClass,
  );

  const allFlightCabinClassOption: OptionModel = new OptionModel();
  allFlightCabinClassOption.value = '';
  allFlightCabinClassOption.label = t('searchBarCabinClassSelector.option.all_cabin_classes');

  const options: OptionModel[] = [
    allFlightCabinClassOption,
    ...settingsToSelectOptions(availableFlightCabinClasses, t),
  ];

  const getLabel = () =>
    options.find((cabinClass) => cabinClass.value === searchFlightCabinClass).label;

  return (
    <Dropdown title={t('searchBarCabinClassSelector.title.cabinClassSelector')} label={getLabel()}>
      {options.map((option) => (
        <Radio
          key={option.value || 'cabin_all'}
          id={option.value || 'cabin_all'}
          name={option.value || 'cabin_all'}
          value={option.value}
          label={option.label}
          checked={option.value === searchFlightCabinClass}
          onChange={() => dispatch(setSearchFlightCabinClass(option.value))}
        />
      ))}
    </Dropdown>
  );
};
