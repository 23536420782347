import * as React from 'react';
import { connect } from 'react-redux';
import { Trans } from 'react-i18next';

// Utils
import { t } from '@toolkit/util/i18n';
import { baseErrorCond, navigate, notifyError } from '@toolkit/util/app';
import { cond, equals, verifyProperty } from '@src/shared/src/util/general';
import { AppContextProp, withAppContext } from '@toolkit/util/AppContext';
import { isHotelOnlySearch } from '@src/shared/src/util/search';
// Constants
import { ERRORS } from '@src/shared/src/const/app';
import { INITIAL_SEARCH_LIST } from '@toolkit/const/app';
// Actions
import { filterActions, hotelActions } from '@src/shared/src/actions';
import { setUiMobileFilterOverlay, setUiMobileNoScrolling } from '@pod/ui/actions';
import { uiMobileFilterOverlay, uiMobileNoScrolling } from '@pod/ui/selectors';
// Models
import {
  BasketModel,
  HotelModel,
  OrganizationModel,
  SearchModel,
  TravelSearchAggregatorModel,
  UserModel,
  RedeemingAllowanceModel,
  EventListingModel,
  CoordinatesModel,
} from '@src/shared/src/models';
// Interfaces
import { selectors } from '@src/shared/src';
import { ConnectedRedux, IRootState } from '@src/store';
// Components
import {
  BookedHotel,
  HotelMobileFilterButtons,
  HotelMobileNavbar,
  HotelsHeader,
  HotelsList,
  HotelsMap,
  RequestHotelLink,
  SelectedHotel,
} from '@pod/hotels/components';
import HotelSideBarConn from './HotelSideBarConn';
import {
  AllowanceSelectionNotification,
  BENotificationBar,
  InformationOverlay,
  LoaderOverlay,
  LoaderOverlayBody,
  NoResultsWarning,
  ScrollToTop,
  SearchExpiredBanner,
} from '@toolkit/ui';
import { AggregatorWarnings } from '@pod/trips/components';
import { SearchEvents } from '@src/components/SearchEvents';
import { RedeemedSearchNotification } from '@pod/checkout/components';
import LoaderResults from '@pod/trips/components/LoaderResults';
// Styles
import '../styles/HotelsConn.scss';

type Props = ConnectedRedux<IRootState> &
  AppContextProp & {
    hotels: HotelModel[];
    totalHotelsLen: number;
    hotelSortingFilter: string;
    search: SearchModel;
    basket: BasketModel;
    hotelWarnings: TravelSearchAggregatorModel[];
    hotelRoomCount: number;
    uiMobileFilterOverlay: boolean;
    uiMobileNoScrolling: boolean;
    profile: UserModel;
    organization: OrganizationModel;
    redeemingAllowance: RedeemingAllowanceModel;
    eventListing: EventListingModel;
  };
const initialState = {
  isHotelsLoading: false,
  displayHotelsList: true,
  hotelOpenedDetailsId: '-1',
  showFailedOverlay: false,
  isBasketLocked: false,
  visibleHotelsCount: INITIAL_SEARCH_LIST.NUM,
};
type State = Readonly<typeof initialState>;

class HotelsConn extends React.Component<Props, State> {
  readonly state: State = initialState;

  public componentWillMount() {
    this.setState({ isHotelsLoading: true });
    this.props.dispatch(
      hotelActions.fetchHotelsAsync.request({
        onSuccess: () => this.setState({ isHotelsLoading: false }),
        onError: cond([
          [
            equals(ERRORS.FAILED),
            () =>
              this.setState({
                isHotelsLoading: false,
                showFailedOverlay: true,
              }),
          ],
          [
            equals(ERRORS.DETAILS_FAILED),
            () => notifyError(ERRORS.DETAILS_FAILED, t('hotelsConn.notification.detailsFailed')),
          ],
          ...baseErrorCond,
        ]),
        searchId: this.props.search.id,
      }),
    );
  }

  public componentWillReceiveProps(nextProps: Props) {
    this.setState({ isBasketLocked: !verifyProperty(true, nextProps.basket, ['editable']) });
  }

  private toggleDisplayHotelList = () =>
    this.setState({ displayHotelsList: !this.state.displayHotelsList });

  private setHotelOpenedDetailsId = (hotelId: string) =>
    this.setState({ hotelOpenedDetailsId: hotelId });

  private getLoaderOverlay = () => {
    if (this.state.isHotelsLoading) {
      if (isHotelOnlySearch(this.props.search)) {
        return (
          <LoaderOverlay
            showSpinner={false}
            showInBox={true}
            body={
              <LoaderOverlayBody
                title={t('loaderOverlay.title.pleaseHoldOn')}
                subTitle={
                  <Trans i18nKey="loaderOverlay.subTitle.searchingForBestOptions">
                    <br />
                  </Trans>
                }
                showHotel={true}
              />
            }
          />
        );
      }
      return <LoaderOverlay showSpinner={true} title={t('global.processingTrip')} />;
    }
  };

  private toggleMobileFilterOverlay = () => {
    if (this.props.uiMobileFilterOverlay) {
      this.props.dispatch(setUiMobileFilterOverlay(false));
      this.props.dispatch(setUiMobileNoScrolling(false));
    } else {
      this.props.dispatch(setUiMobileFilterOverlay(true));
      this.props.dispatch(setUiMobileNoScrolling(true));
    }
  };

  public render() {
    const { search, appContext, uiMobileNoScrolling } = this.props;
    return (
      <div className={`main-container ${uiMobileNoScrolling ? 'no-scrolling' : ''}`}>
        <BENotificationBar currentPage="HotelSearch" />
        {this.getLoaderOverlay()}
        {!appContext.isMediaBPNotMobile && <HotelMobileNavbar search={search} />}
        <div className="row">
          <HotelSideBarConn
            setHotelDetailsOpenedId={this.setHotelOpenedDetailsId}
            isOpen={this.props.uiMobileFilterOverlay}
          />
          <section className="content content-trip">
            <SearchExpiredBanner isSearchExpired={this.props.search.expired} />
            <RedeemedSearchNotification redeemingAllowance={this.props.redeemingAllowance} />
            <AllowanceSelectionNotification
              redeemingAllowance={this.props.redeemingAllowance}
              selection="hotel"
            />
            <AggregatorWarnings searchWarnings={this.props.hotelWarnings} />
            <SearchEvents eventListing={this.props.eventListing} />
            <div className="content-wrapper">
              <SelectedHotel />
              {this.state.isBasketLocked && <BookedHotel />}
              {this.state.isHotelsLoading ? (
                <LoaderResults finished={!this.state.isHotelsLoading} />
              ) : (
                <HotelsHeader
                  setHotelDetailsOpenedId={this.setHotelOpenedDetailsId}
                  filteredHotelsLen={this.props.hotels.length}
                  totalHotelsLen={this.props.totalHotelsLen}
                  searchId={this.props.search.id}
                  isDisplayHotelList={this.state.displayHotelsList}
                  isSearchingForOnlyHotel={isHotelOnlySearch(search)}
                  onToggleDisplayList={this.toggleDisplayHotelList}
                />
              )}
              {this.state.displayHotelsList ? (
                <HotelsList
                  searchId={this.props.search.id}
                  showBasketLockedOverlay={() => this.setState({ isBasketLocked: true })}
                  filteredHotels={this.props.hotels}
                  hotelOpenedDetailsId={this.state.hotelOpenedDetailsId}
                  hotelRoomCount={this.props.hotelRoomCount}
                  onShowMore={(visibleHotelsCount) => this.setState({ visibleHotelsCount })}
                />
              ) : (
                <HotelsMap
                  hotels={this.props.hotels}
                  location={new CoordinatesModel(this.props.search.arrLat, this.props.search.arrLng)}
                  center={new CoordinatesModel(this.props.search.arrLat, this.props.search.arrLng)}
                  handleViewOffer={this.setHotelOpenedDetailsId}
                  showHotelsList={() => this.toggleDisplayHotelList()}
                />
              )}
              <HotelMobileFilterButtons
                showHotelsList={this.state.displayHotelsList}
                toggleDisplayHotelList={this.toggleDisplayHotelList}
                filterOverlayOpen={this.props.uiMobileFilterOverlay}
                toggleMobileFilterOverlay={this.toggleMobileFilterOverlay}
              />
              {this.props.totalHotelsLen > 0 && this.props.hotels.length === 0 && (
                <NoResultsWarning
                  title={t('tripsConn.title.noFilteredResults')}
                  bodyText={t('tripsConn.text.noFilteredResults')}
                  buttonText={t('tripsConn.button.noFilteredResults.clickHere')}
                  onClick={() => this.props.dispatch(filterActions.initializeHotelFilters(''))}
                />
              )}
              {!this.state.isHotelsLoading &&
                (!this.state.displayHotelsList ||
                  this.props.hotels.length <= this.state.visibleHotelsCount) && (
                  <RequestHotelLink profile={this.props.profile} />
                )}
            </div>
          </section>
        </div>
        {this.state.showFailedOverlay && (
          <InformationOverlay
            isOpen={true}
            icon="icon-radio_button_unchecked2 color-warning"
            title={t('informationOverlay.title.searchFailed')}
            body={t('informationOverlay.body.searchFailed')}
            showButton={true}
            buttonText={t('informationOverlay.button.restartSearch')}
            onButtonClicked={() => navigate('/')}
          />
        )}
        {this.state.displayHotelsList && <ScrollToTop />}
      </div>
    );
  }
}

const mapStateToProps = (state: IRootState) => ({
  totalHotelsLen: state.hotels.hotels.length,
  hotels: state.hotels.filteredHotels,
  hotelSortingFilter: state.filters.hotelSortingFilter.current,
  search: selectors.search.search(state.search),
  basket: state.checkout.basket,
  hotelWarnings: selectors.search.hotelWarnings(state),
  hotelRoomCount: selectors.search.searchHotelRoomCount(state.search),
  uiMobileFilterOverlay: uiMobileFilterOverlay(state),
  uiMobileNoScrolling: uiMobileNoScrolling(state),
  profile: state.adminUser.profile,
  organization: state.organization.org,
  redeemingAllowance: state.checkout.redeemingAllowance,
  eventListing: state.search.eventListing,
});
export default connect(mapStateToProps)(withAppContext(HotelsConn));
